// react
import React, {Component, useEffect} from "react";

// third-party
import {connect} from "react-redux";
import {Helmet} from "react-helmet-async";
import {Link, Redirect} from "react-router-dom";

// application
import Collapse from "../shared/Collapse";
import Currency from "../shared/Currency";
import PageHeader from "../shared/PageHeader";
import {Check9x7Svg, InfoSvg} from "../../svg";
import {toast} from "react-toastify";

// data stubs
import {FormattedMessage, injectIntl} from "react-intl";

import {url} from "../../helper";
import Breadcrumbs from "../shared/Breadcrumbs";

const validEmailRegex = RegExp(
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);

const validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach(
        // if we have an error string set valid to false
        (val) => val.length > 0 && (valid = false)
    );
    return valid;
};
const phonenumber = RegExp(/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/);

//const url='https://megalight-api.fidem.am';

class ShopPageCheckout extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            customer: this.props.customer,
            checkbox: false,
            token: this.props.token,
            locale: this.props.locale,
            payment: "cashondelivery",
            payments: null,
            ShippingAddress: true,
            savedAddresses: [],
            notes: "",
            fullName: "",
            lname: "",
            shipingLname: "",
            country: "AM",
            city: "Yerevan",
            shipingCity: "Yerevan",
            name: "",
            street: "",
            shipingStreet: "",
            shipingPhone: "",
            phone: "",
            appartment: "",
            email: "",
            shipingEmail: "",
            checkboxCheck: false,
            isChecked: 0,
            companyName: "",
            shipingCompanyName: "",
            errors: {
                fullName: "",
                companyName: "",
                shipingCompanyName: "",
                city: "",
                name: "",
                checkboxCheck: false,
                street: "",
                shipingEmail: "",
                shipingCity: "",
                shipingLname: "",
                shipingStreet: "",
                appartment: "",
                shipingPhone: "",
                phone: "",
                email: "",
            },
            addNewAddressCheck: 0,
            addressSaveBilling: false,
            shippingMethods: [],
            extraLines: [
                // shipping, taxes, fees, .etc
                {
                    type: "shipping",
                    title: "Shipping",
                    price: 0,
                },
                {
                    type: "tax",
                    title: "Tax",
                    price: 0,
                },
            ],
            shippingPrice: 0,
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps) {
            let save_shipping = {
                method: "GET",
            };
            fetch(url + `/api/payments?locale=${nextProps.locale}&token=${this.state.customer.token}`, save_shipping)
                .then((responce) => responce.json())
                .then((res) => {
                    this.setState({payments: res});
                })
                .catch((err) => console.error(err));


            const truToken = this.state.customer.authenticated ? `&token=${this.state.customer.token}` : ""

            fetch(
                url + `/api/shippings?api_token=${localStorage.getItem("api_token")}&locale=${this.props.locale}${truToken}`,
                {
                    method: "GET",
                }
            )
                .then((responce) => responce.json())
                .then((res) => {
                    this.setState({
                        shippingMethods: res.map((el, index) => {
                            return {
                                ...el,
                                checked: !index
                            }
                        })
                        // checkedShippingMethod: res[0].method
                    });
                })
                .catch((err) => console.error(err));
        }
    }

    componentDidMount() {
        if (window.location.search.includes("?errNo=swr_3")) {
            let message = <FormattedMessage id="payment_failed_message" defaultMessage="payment failed message"/>;
            toast.success(message);
        }

        const costumerData = JSON.parse(localStorage.getItem("userData"));
        const companyName = JSON.parse(localStorage.getItem("companyName"));
        if (this.state.customer.token) {
            fetch(`${url}/api/addresses?token=${this.state.customer.token}`)
                .then((responce) => responce.json())
                .then((res) => {
                    if (!res.data.length) {
                        this.setState({
                            fullName: costumerData.first_name + " " + costumerData.last_name,
                            // lname: costumerData.last_name,
                            email: costumerData.email,
                            phone: costumerData.phone,
                            isChecked: costumerData.id,
                            companyName: companyName
                        });
                    } else {
                        this.setState({savedAddresses: res.data});
                        this.setState({
                            fullName: res.data[0].first_name + " " + res.data[0].last_name,
                            // lname: res.data[0].last_name,
                            city: res.data[0].city,
                            street: res.data[0].address1,
                            email: costumerData.email,
                            phone: res.data[0].phone,
                            isChecked: res.data[0].id,
                            companyName: companyName
                        });
                    }
                })
                .catch((err) => console.error(err));
        }
        let save_shipping = {
            method: "GET",
        };

        fetch(url + `/api/payments?locale=${this.state.locale}&token=${this.state.customer.token}`, save_shipping)
            .then((responce) => responce.json())
            .then((res) => {
                this.setState({payments: res});
            })
            .catch((err) => console.error(err));

        const truToken = this.state.customer.authenticated ? `&token=${this.state.customer.token}` : ""

        fetch(
            url + `/api/shippings?api_token=${localStorage.getItem("api_token")}&locale=${this.props.locale}${truToken}`,
            {
                method: "GET",
            }
        )
            .then((responce) => responce.json())
            .then((res) => {
                this.setState({
                    shippingMethods: res.map((el, index) => {
                        return {
                            ...el,
                            checked: !index
                        }
                    })
                    // checkedShippingMethod: res[0].method
                });
            })
            .catch((err) => console.error(err));
    }

    handlePaymentChange = (event) => {
        if (event.target.checked) {
            this.setState({payment: event.target.value});
        }
    };

    renderTotals() {
        const {cart} = this.props;
        const subTotal = (+cart.sub_total).toFixed(2) + " " + cart.currency_symbol
        const shippingPrice = this.state.shippingPrice.toFixed(2) + " " + cart.currency_symbol

        // const extraLines = cart.extraLines.map((extraLine, index) => {
        //     if (extraLine.type === "shipping") {
        //         return (
        //             <tr key={index}>
        //                 <th>
        //                     <FormattedMessage id="check.shipping" defaultMessage="Shipping" />
        //                 </th>
        //                 <td>
        //                     <Currency value={+extraLine.price} />
        //                 </td>
        //             </tr>
        //         );
        //     }
        // });

        return (
            <div className="checkout__totals-subtotals">
                <div className="checkout__subtotals">
                    <span className="subtotal__text">
                        <FormattedMessage id="check.subtotal" defaultMessage="Subtotal"/>
                    </span>
                    <span className="subtotal__value">
                        {subTotal}
                        {/*TODO !!! check this issue*/}
                        {/*<Currency value={+cart.sub_total}/>*/}
                    </span>
                </div>
                <div className="checkout__shipping">
                    <div>
                        <FormattedMessage id="check.shipping" defaultMessage="shipping"/>
                    </div>
                    <div>
                        {shippingPrice}
                    </div>
                </div>
            </div>
        );
    }

    renderCart() {
        const {cart} = this.props;
        const findMethodRate = this.state.shippingMethods?.find(elem => elem.checked === true)
        const grantTotal = (+cart.grand_total + this.state.shippingPrice).toFixed(2) + " " + cart.currency_symbol

        const items = cart.items.map((item) => {

            const imageUrl = item.product.base_image.small_image_url

            return (
                <div className="checkout__product" key={item.id}>
                    <div className="product-name__img">
                        <div className="product__image">
                            <img width={70} src={imageUrl} alt="Product image"/>
                        </div>
                        <div
                            className="product__name">{`${item.product.name || item.product.product_name} × ${item.quantity}`}</div>
                    </div>
                    <div className="product__price">
                        <Currency value={+item.total}/>
                    </div>
                </div>
            );
        });

        return (
            <div className="checkout__totals">
                <div className="checkout__totals-header">
                    <div>
                        <span>
                            <FormattedMessage id="global.product" defaultMessage="Product"/>
                        </span>
                        <span>
                            <FormattedMessage id="global.total" defaultMessage="Total"/>
                        </span>
                    </div>
                </div>
                <div className="checkout__totals-products">{items}</div>
                {this.renderTotals()}
                <div className="checkout__totals-footer">
                    <div>
                        <FormattedMessage id="global.total" defaultMessage="Total"/>
                    </div>
                    <div>
                        {/*TODO!!! check this issue*/}
                        {grantTotal}
                        {/*<Currency value={findMethodRate ? +cart.total + +findMethodRate.rate || 0 : +cart.total}/>*/}
                    </div>
                </div>
            </div>
        );
    }

    renderPaymentsList() {
        const {payment: currentPayment} = this.state;
        if (this.state.payments) {
            const payments = this.state.payments.map((payment) => {
                const renderPayment = ({setItemRef, setContentRef}) => (
                    <>
                        <li className="payment-methods__item" ref={setItemRef}>
                            {payment.status !== "active" && <span className="disabled-style"></span>}
                            <label className="payment-methods__item-header">
                                <span className="payment-methods__item-radio input-radio">
                                    <span className="input-radio__body">
                                        <input
                                            type="radio"
                                            className="input-radio__input"
                                            name="checkout_payment_method"
                                            value={payment.method}
                                            checked={currentPayment === payment.method}
                                            onChange={(e) => {
                                                if (payment.status === "active") {
                                                    this.handlePaymentChange(e);
                                                } else {
                                                    toast.success("You have reached your credit limit");
                                                }
                                            }}
                                        />

                                        <span className="input-radio__circle"/>
                                    </span>
                                </span>
                                <span className="payment-methods__item-title">
                                    {payment.title}
                                    <br/>
                                    {currentPayment === "moneytransfer" && payment.method === "moneytransfer"
                                        ? payment.description
                                        : ""}
                                </span>
                            </label>
                            <div className="payment-methods__item-container" ref={setContentRef}>
                                <img
                                    style={{
                                        margin: "auto",
                                        display: "block",
                                        width: "200px",
                                    }}
                                    src={payment.img}
                                    alt=""
                                />
                            </div>
                        </li>
                        {payment.method === "moneytransfer" && payment.status === "disabled" ? (
                            <span className="payment-methods__available">
                                <FormattedMessage
                                    id="The.available.credit.amount.is"
                                    defaultMessage="The available credit amount is"
                                />{" "}
                                <Currency value={payment.available}/>
                            </span>
                        ) : (
                            ""
                        )}
                    </>
                );

                return (
                    <Collapse
                        key={payment.method}
                        open={currentPayment === payment.method}
                        toggleClass="payment-methods__item--active"
                        render={renderPayment}
                    />
                );
            });

            return (
                <div className="payment-methods">
                    <ul className="payment-methods__list">{payments}</ul>
                </div>
            );
        }
    }

    openAddress = () => {
        this.setState({ShippingAddress: !this.state.ShippingAddress});
    }

    saveAddressBilling = () => {
        this.setState({addressSaveBilling: !this.state.addressSaveBilling})
    }

    handleChange = (event) => {
        // event.preventDefault();
        const {name, value} = event.target;


        if (name === "checkboxCheck") {
            this.setState({
                [name]: value,
                checkboxCheck: !this.state.checkboxCheck,
            });
        } else {
            this.setState({[name]: value});
        }
    };

    chacking = () => {
        const errors = this.state.errors;

        errors.checkboxCheck = this.state.checkboxCheck === false ? "This field is required" : "";

        errors.fullName = this.state.fullName === "" ? "This field is required" : "";

        errors.companyName = this.state.companyName === "" && this.state.customer.authenticated && this.state.customer.role === "Manager" ? "This field is required" : "";

        errors.city = this.state.city === "" ? "This field is required" : "";

        errors.email = validEmailRegex.test(this.state.email) ? "" : "This field is required";

        errors.street = this.state.street === "" ? "This field is required" : "";

        errors.phone = phonenumber.test(this.state.phone) ? "" : "This field is required";

        if (!this.state.ShippingAddress) {
            errors.name = this.state.name === "" ? "This field is required" : "";

            errors.shipingCompanyName = this.state.shipingCompanyName === "" && this.state.customer.authenticated && this.state.customer.role === "Manager" ? "This field is required" : "";

            errors.shipingStreet = this.state.shipingStreet === "" ? "This field is required" : "";

            errors.shipingPhone = phonenumber.test(this.state.shipingPhone) ? "" : "This field is required"

            // errors.shipingLname = this.state.shipingLname.length < 5 ? "This field is required" : "";

            errors.shipingCity = this.state.shipingCity === "" ? "This field is required" : "";

            // errors.shipingEmail = this.state.shipingEmail.length < 5 ? "This field is required" : "";

            this.setState({errors});
        }

        this.setState({errors});
    };

    destroyCart() {
        let local = localStorage.getItem("state");
        let obj = JSON.parse(local);
        delete obj.cart;

        localStorage.setItem("state", JSON.stringify(obj));
    }

    requestOrder() {
        const {cart, locale} = this.props;

        const headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
        };

        let options, options_payment, save_shipping;
        let street = this.state.street;
        if (typeof street == "string") street = [this.state.street];

        let fullNameArr = this.state.fullName.split(" ");
        let first_name = fullNameArr[0];
        let last_name = fullNameArr[1] || ".";
        let fullNameArrship = this.state.name.split(" ");
        let first_name_ship = fullNameArrship[0];
        let last_name_ship = fullNameArrship[1] || ".";


        let billing = {
            use_for_shipping: this.state.ShippingAddress,
            address1: street,
            email: this.state.email,
            first_name: first_name,
            last_name: last_name,
            city: this.state.city,
            country: "AM",
            state: "Yerevan",
            postcode: "75017",
            phone: this.state.phone,
            company_name: this.state.companyName,
        };
        let shipping;

        if (!this.state.ShippingAddress) {
            let street1 = this.state.shipingStreet;
            if (typeof street1 == "string") street1 = [street1];
            shipping = {
                // email: this.state.shipingEmail,
                email: this.state.email,
                last_name: last_name_ship,
                city: this.state.shipingCity,
                first_name: first_name_ship,
                country: "AM",
                state: "Yerevan",
                postcode: "75017",
                company_name: this.state.shipingCompanyName,
                address1: street1,
                phone: this.state.shipingPhone,
                use_for_shipping: this.state.ShippingAddress,
            };
        } else {
            shipping = {
                address1: [""],
            };
        }

        if (this.state.customer.token) {
            options = {
                method: "POST",
                headers: headers,
                body: JSON.stringify({
                    billing: this.state.ShippingAddress ? billing : shipping,
                    shipping: this.state.ShippingAddress ? shipping : billing,
                    locale: this.props.locale,
                    // api_token: cart.cart_token,
                    // token: this.state.customer.token,
                }),
            };
            options_payment = {
                method: "POST",
                headers: headers,
                body: JSON.stringify({
                    payment: {method: this.state.payment},
                    api_token: localStorage.getItem("api_token"),
                    token: this.state.customer.token,
                    locale: this.props.locale,
                }),
            };
        } else {
            options = {
                method: "POST",
                headers: headers,
                body: JSON.stringify({
                    billing: this.state.ShippingAddress ? billing : shipping,
                    shipping: this.state.ShippingAddress ? shipping : billing,
                    api_token: localStorage.getItem("api_token"),
                    locale: this.props.locale,
                }),
            };
            options_payment = {
                method: "POST",
                headers: headers,
                body: JSON.stringify({
                    payment: {method: this.state.payment},
                    api_token: localStorage.getItem("api_token"),
                    locale: this.props.locale,
                }),
            };
        }

        let shippingMethod = this.state.shippingMethods.find(el => el.checked).method

        save_shipping = {
            method: "POST",
            headers: headers,
            body: JSON.stringify({
                shipping_method: `${shippingMethod}_${shippingMethod}`,
                api_token: localStorage.getItem("api_token"),
                locale: this.props.locale,
            }),
        };
        if (this.state.addressSaveBilling) {
            fetch(`${url}/api/addresses/create`, {
                method: "POST",
                headers: headers,
                body: JSON.stringify({
                    ...billing,
                    token: this.state.customer.token
                }),
            })
        }

        fetch(
            url +
            `/api/checkout/save-address?api_token=${localStorage.getItem("api_token")}${
                this.state.customer.token ? `&token=${this.state.customer.token}` : ""
            }`,
            options
        )
            .then((response) => {
                if (response.ok) {
                    fetch(
                        url +
                        `/api/checkout/save-shipping${
                            this.state.customer.token ? `?token=${this.state.customer.token}` : ""
                        }`,
                        save_shipping
                    )
                        .then((res) => {
                            if (res.ok) {
                                fetch(url + "/api/checkout/save-payment", options_payment)
                                    .then((rsponce) => {
                                        if (rsponce.ok) {
                                            let body;
                                            if (this.state.customer.token) {
                                                body = {
                                                    api_token: localStorage.getItem("api_token"),
                                                    token: this.state.customer.token,
                                                    description: this.state.notes,
                                                    locale: this.props.locale,
                                                };
                                            } else {
                                                body = {
                                                    api_token: localStorage.getItem("api_token"),
                                                    description: this.state.notes,
                                                    locale: this.props.locale,
                                                };
                                            }

                                            fetch(url + "/api/checkout/save-order", {
                                                method: "POST",
                                                headers: headers,
                                                body: JSON.stringify(body),
                                            })
                                                .then((res) => res.json())
                                                .then((res) => {
                                                    if (res.success) {
                                                        if (res.redirect_url) {
                                                            // this.destroyCart();

                                                            window.location = res.redirect_url;
                                                        } else {
                                                            this.destroyCart();
                                                            window.location = `${locale}/thanks?orderID=` + res.order.id;
                                                        }
                                                    }
                                                })
                                                .catch((err) => console.log(err, "err"));
                                        }
                                    })
                                    .then((res) => {
                                        console.log(res, "res");
                                    });
                            }
                        })
                        .catch((err) => console.error(err));
                }
            })
            .catch((err) => console.log(err, "err"));

        //   return <Redirect to="/thanks" />;
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({loading: true});
        this.chacking();
        if (validateForm(this.state.errors)) {
            this.requestOrder();
        } else {
            this.setState({loading: false});
            console.log("Invalid Form", this.state.errors);
        }
    };

    render() {
        const {errors} = this.state;
        const {cart, location, locale} = this.props;
        const {intl} = this.props;


        const translatedPlaceholder = (id, defaultMessage) => {
            return intl.formatMessage({id, defaultMessage});
        }

        if (cart?.items?.length < 1) {
            return <Redirect to="cart"/>;
        }

        const handleCheck = (e) => {
            // this.setState({checkedShippingMethod: this.state.shippingMethods.find(el => el.method === e.target.id).method})
            let shippingMethods = this.state.shippingMethods
            this.setState({
                shippingMethods: shippingMethods.map(el => {
                    if(el.method === e.target.id){
                        this.setState({shippingPrice: +el.rate})
                    }
                    return {
                        ...el,
                        checked: el.method === e.target.id
                    }
                })
            })
        }

        const breadcrumb = [
            {title: <FormattedMessage id="home" defaultMessage="home"/>, url: url},
            {title: <FormattedMessage id="cart.cart" defaultMessage="CART"/>, url: `/${locale}/shop/cart`},
            {title: <FormattedMessage id="checkout" defaultMessage="Checkout"/>, url: location.pathname},
        ];

        return (
            <React.Fragment>
                <Helmet>
                    <title>Checkout</title>
                </Helmet>

                {/*<div className="page-header">*/}
                {/*    <div className="page-header__container container">*/}
                {/*        <div className="page-header__title">*/}
                {/*            <h1>*/}
                {/*                <FormattedMessage id="checkout" defaultMessage="Checkout"/>*/}
                {/*            </h1>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <Breadcrumbs breadcrumb={breadcrumb}/>

                <PageHeader header=<FormattedMessage id="checkout" defaultMessage="Checkout"/>/>

                <div className="checkout block">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-lg-6 col-xl-7">
                                <div className="card mb-lg-0">
                                    <form>
                                        <div className="card-body">
                                            <h3 className="card-title">
                                                <FormattedMessage
                                                    id="checkout.shipping"
                                                    defaultMessage="Shipping details"
                                                />
                                            </h3>

                                            <div className="form-group adresses">
                                                {this.state.savedAddresses.length > 0 &&
                                                    this.state.savedAddresses.map((e, i) => {
                                                        return (
                                                            <div className="choose_fm" key={e.id}
                                                                 onClick={(event) => {
                                                                     this.setState({
                                                                         isChecked: event.target.id,
                                                                         fullName: e.first_name + " " + e.last_name,
                                                                         // lname: e.last_name,
                                                                         city: e.city,
                                                                         street: e.address1,
                                                                         // email: e.email,
                                                                         phone: e.phone,
                                                                         addNewAddressCheck: 0,
                                                                     });
                                                                 }}
                                                            >
                                                                <label className="radio_fm">
                                                                    {e.address1[0]}
                                                                    <input
                                                                        type="radio"
                                                                        name="radio"
                                                                        id={e.id}
                                                                        checked={e.id === +this.state.isChecked}
                                                                        value={this.state.fullName}
                                                                        // onChange={(event) => {
                                                                        //     console.log(event.target.id)
                                                                        //     this.setState({isChecked: event.target.id})
                                                                        //     // this.setState({
                                                                        //     //     fullName: e.first_name + " " + e.last_name,
                                                                        //     //     // lname: e.last_name,
                                                                        //     //     city: e.city,
                                                                        //     //     street: e.address1,
                                                                        //     //     // email: e.email,
                                                                        //     //
                                                                        //     //     phone: e.phone,
                                                                        //     //     addNewAddressCheck: 0
                                                                        //     // });
                                                                        //     console.log('asdasdasdasd')
                                                                        //     // this.setState({addNewAddressCheck: 0})
                                                                        // }}
                                                                    />
                                                                    <span className="checkmark"></span>
                                                                </label>
                                                            </div>
                                                        );
                                                    })}

                                                {this.state.savedAddresses.length > 0 && (
                                                    <div onClick={() => this.setState({addNewAddressCheck: 1})}
                                                         className="choose_fm">
                                                        <label className="radio_fm">
                                                            <FormattedMessage
                                                                id="add_new_address"
                                                                defaultMessage="Add new address"
                                                            />
                                                            <input
                                                                type="radio"
                                                                name="radio"
                                                                checked={this.state.addNewAddressCheck === 1}
                                                            />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </div>
                                                )}
                                            </div>

                                            {/*<div onClick={() => this.setState({ addNewAddressCheck: 1})} className="choose_fm">*/}
                                            {/*    <label className="radio_fm">*/}
                                            {/*        Add new address*/}
                                            {/*        <input*/}
                                            {/*            type="radio"*/}
                                            {/*            name="radio"*/}
                                            {/*            checked={this.state.addNewAddressCheck === 1}*/}
                                            {/*        />*/}
                                            {/*        <span className="checkmark"></span>*/}
                                            {/*    </label>*/}
                                            {/*</div>*/}

                                            {((this.state.savedAddresses.length > 0 && this.state.addNewAddressCheck === 1) || this.state.savedAddresses.length <= 0) && (
                                                <>
                                                    {/*<div className="form-row">*/}
                                                    {/*firstName*/}
                                                    {/*<div className="form-group col-md-6">*/}
                                                    {/*    <FormattedMessage id="checkout.name" defaultMessage="Name">*/}
                                                    {/*        {(placeholder) => (*/}
                                                    {/*            <input*/}
                                                    {/*                onChange={this.handleChange}*/}
                                                    {/*                value={this.state.fullName}*/}
                                                    {/*                type="text"*/}
                                                    {/*                name="fullName"*/}
                                                    {/*                className="form-control"*/}
                                                    {/*                id="checkout-first-name"*/}
                                                    {/*                placeholder={placeholder}*/}
                                                    {/*            />*/}
                                                    {/*        )}*/}
                                                    {/*    </FormattedMessage>*/}
                                                    {/*    {errors.fullName.length > 0 && (*/}
                                                    {/*        <span className="alert-danger">*/}
                                                    {/*            <FormattedMessage*/}
                                                    {/*                id="account.error.required"*/}
                                                    {/*                defaultMessage={errors.fullName}*/}
                                                    {/*            />*/}
                                                    {/*        </span>*/}
                                                    {/*    )}*/}
                                                    {/*</div>*/}

                                                    {/*lastName*/}
                                                    {/*<div className="form-group col-md-6">*/}
                                                    {/*    <FormattedMessage id="checkout.lname"*/}
                                                    {/*                      defaultMessage="Last Name">*/}
                                                    {/*        {(placeholder) => (*/}
                                                    {/*            <input*/}
                                                    {/*                onChange={this.handleChange}*/}
                                                    {/*                value={this.state.lname}*/}
                                                    {/*                type="text"*/}
                                                    {/*                name="lname"*/}
                                                    {/*                className="form-control"*/}
                                                    {/*                id="checkout-first-name"*/}
                                                    {/*                placeholder={placeholder}*/}
                                                    {/*            />*/}
                                                    {/*        )}*/}
                                                    {/*    </FormattedMessage>*/}
                                                    {/*    {errors.fullName.length > 0 && (*/}
                                                    {/*        <span className="alert-danger">*/}
                                                    {/*            <FormattedMessage*/}
                                                    {/*                id="account.error.required"*/}
                                                    {/*                defaultMessage={errors.fullName}*/}
                                                    {/*            />*/}
                                                    {/*        </span>*/}
                                                    {/*    )}*/}
                                                    {/*</div>*/}

                                                    {/*fullName*/}
                                                    <div className="form-group">
                                                        <FormattedMessage id="checkout.fullName"
                                                                          defaultMessage="FullName">
                                                            {(placeholder) => (
                                                                <input
                                                                    onChange={this.handleChange}
                                                                    value={this.state.fullName}
                                                                    type="text"
                                                                    name="fullName"
                                                                    className="form-control"
                                                                    id="checkout-full-name"
                                                                    placeholder={placeholder + " *"}
                                                                />
                                                            )}
                                                        </FormattedMessage>
                                                        {errors.fullName.length > 0 && (
                                                            <span className="alert-danger">
                                                                <FormattedMessage
                                                                    id="account.error.required"
                                                                    defaultMessage={errors.fullName}
                                                                />
                                                            </span>
                                                        )}
                                                    </div>

                                                    {(!this.state.customer.authenticated || this.state.customer.authenticated && this.state.customer.role === "Manager") &&
                                                        <div className="form-group">
                                                            <FormattedMessage id="checkout.companyName"
                                                                              defaultMessage="company name">
                                                                {(placeholder) => (
                                                                    <input
                                                                        onChange={this.handleChange}
                                                                        value={this.state.companyName}
                                                                        type="text"
                                                                        name="companyName"
                                                                        className="form-control"
                                                                        id="checkout-company-name"
                                                                        placeholder={this.state.customer.authenticated && this.state.customer.role === "Manager" ? placeholder + " *" : placeholder}
                                                                    />
                                                                )}
                                                            </FormattedMessage>
                                                            {errors.companyName.length > 0 && (
                                                                <span className="alert-danger">
                                                                <FormattedMessage
                                                                    id="account.error.required"
                                                                    defaultMessage={errors.companyName}
                                                                />
                                                            </span>
                                                            )}
                                                        </div>
                                                    }
                                                    {/*</div>*/}

                                                    <div className="form-row">
                                                        <div className="form-group col-md-6">
                                                            <FormattedMessage id="global.country"
                                                                              defaultMessage="Country">
                                                                {(placeholder) => (
                                                                    <input
                                                                        onChange={this.handleChange}
                                                                        value={this.state.country}
                                                                        type="text"
                                                                        name="country"
                                                                        className="form-control"
                                                                        id="checkout-country"
                                                                        placeholder={placeholder + " *"}
                                                                        disabled="disabled"
                                                                    />
                                                                )}
                                                            </FormattedMessage>
                                                        </div>

                                                        <div className="form-group col-md-6">
                                                            <FormattedMessage id="global.city" defaultMessage="City">
                                                                {(placeholder) => (
                                                                    <input
                                                                        type="text"
                                                                        name="city"
                                                                        onChange={this.handleChange}
                                                                        value={this.state.city}
                                                                        className="form-control"
                                                                        id="checkout-city"
                                                                        placeholder={placeholder + " *"}
                                                                        disabled={true}
                                                                    />
                                                                )}
                                                            </FormattedMessage>
                                                            {errors.city.length > 0 && (
                                                                <span className="alert-danger">
                                                            <FormattedMessage
                                                                id="account.error.required"
                                                                defaultMessage={errors.city}
                                                            />
                                                        </span>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="form-group">
                                                        <FormattedMessage id="address" defaultMessage="Address">
                                                            {(placeholder) => (
                                                                <input
                                                                    onChange={this.handleChange}
                                                                    value={this.state.street}
                                                                    name="street"
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="checkout-street"
                                                                    placeholder={placeholder + " *"}
                                                                />
                                                            )}
                                                        </FormattedMessage>

                                                        {errors.street.length > 0 && (
                                                            <span className="alert-danger">
                                                        <FormattedMessage
                                                            id="account.error.required"
                                                            defaultMessage={errors.street}
                                                        />
                                                    </span>
                                                        )}
                                                    </div>

                                                    <div className="form-row">
                                                        <div className="form-group col-md-6">
                                                            <FormattedMessage id="global.email" defaultMessage="Email">
                                                                {(placeholder) => (
                                                                    <input
                                                                        value={this.state.email}
                                                                        onChange={this.handleChange}
                                                                        type="email"
                                                                        name="email"
                                                                        className="form-control"
                                                                        id="checkout-email"
                                                                        placeholder={placeholder + " *"}
                                                                    />
                                                                )}
                                                            </FormattedMessage>
                                                            {errors.email.length > 0 && (
                                                                <span className="alert-danger">
                                                            <FormattedMessage
                                                                id="account.error.required"
                                                                defaultMessage={errors.email}
                                                            />
                                                        </span>
                                                            )}
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <FormattedMessage id="global.phoneNumber"
                                                                              defaultMessage="Phone number">
                                                                {(placeholder) => (
                                                                    <input
                                                                        onChange={this.handleChange}
                                                                        value={this.state.phone}
                                                                        name="phone"
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="checkout-phone"
                                                                        placeholder={placeholder + " *"}
                                                                    />
                                                                )}
                                                            </FormattedMessage>
                                                            {errors.phone.length > 0 && (
                                                                <span className="alert-danger">
                                                            <FormattedMessage
                                                                id="account.error.required"
                                                                defaultMessage={errors.phone}
                                                            />
                                                        </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </>
                                            )}

                                            {this.state.addNewAddressCheck === 1 && (
                                                <div className="form-group">
                                                    <div className="form-check">
                                                        <span
                                                            onClick={this.saveAddressBilling}
                                                            className="form-check-input input-check"
                                                        >
                                                            <span className="input-check__body">
                                                                <input
                                                                    className="input-check__input"
                                                                    type="checkbox"
                                                                    id="checkout-different-address"
                                                                />
                                                                <span className="input-check__box"/>
                                                                <Check9x7Svg className="input-check__icon"/>
                                                            </span>
                                                        </span>
                                                        <label>
                                                            <FormattedMessage
                                                                id="save_address"
                                                                defaultMessage="Save Address"
                                                            />
                                                        </label>
                                                    </div>
                                                </div>
                                            )}

                                            <div className="form-group">
                                                <div className="form-check">
                                                    <span
                                                        onClick={this.openAddress}
                                                        className="form-check-input input-check"
                                                    >
                                                        <span className="input-check__body">
                                                            <input
                                                                className="input-check__input"
                                                                type="checkbox"
                                                                id="checkout-different-address"
                                                                defaultChecked={true}
                                                            />
                                                            <span className="input-check__box"/>
                                                            <Check9x7Svg className="input-check__icon"/>
                                                        </span>
                                                    </span>
                                                    <label>
                                                        <FormattedMessage
                                                            id="chekout.shippingDifferent"
                                                            defaultMessage="Ship to a different address?"
                                                        />
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </form>

                                    <div className="card-divider"/>
                                    <div className="card-body">
                                        <h3 className="card-title">
                                            <FormattedMessage
                                                id="shipping.methods"
                                                defaultMessage="Shipping methods"
                                            />
                                        </h3>
                                        {this.state.shippingMethods.length ?
                                            <div className="shipping_methods">
                                                {this.state.shippingMethods.map((el, index) => {
                                                    return (
                                                        <div className="shipping_method" key={index}>
                                                            <div className="option__container">
                                                                <input type="radio"
                                                                       id={el.method}
                                                                       value={el.method_title}
                                                                       checked={el.checked}
                                                                       name="shipping methods"
                                                                       onClick={handleCheck}
                                                                />
                                                                <label htmlFor={el.method}>
                                                                    {el?.rate === "0" ? el.method_title : `${el.method_title} - ${el.rate} ֏`}
                                                                </label>
                                                            </div>
                                                            <div className="option_desc">{el.description}</div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            :
                                            null
                                        }
                                    </div>

                                    <div className="card-divider"/>
                                    <div className="card-body">
                                        {!this.state.ShippingAddress ? (
                                            <>
                                                <h3 className="card-title">
                                                    <FormattedMessage
                                                        id="checkout.billing"
                                                        defaultMessage="Billing details"
                                                    />
                                                </h3>

                                                {/*<div className="form-group adresses">*/}
                                                {/*    {this.state.savedAddresses.length > 0 &&*/}
                                                {/*        this.state.savedAddresses.map((e,i) => {*/}
                                                {/*            return (*/}
                                                {/*                <div className="choose_fm" key={e.id}>*/}
                                                {/*                    <label className="radio_fm">*/}
                                                {/*                        {e.address1[0]}*/}
                                                {/*                        <input*/}
                                                {/*                            type="radio"*/}
                                                {/*                            name="radio"*/}
                                                {/*                            id={e.id}*/}
                                                {/*                            checked={e.id ==this.state.isChecked}*/}
                                                {/*                            value={this.state.fullName}*/}
                                                {/*                            onChange={(event) => {*/}
                                                {/*                                this.setState({isChecked:event.target.id})*/}
                                                {/*                                this.setState({*/}
                                                {/*                                    fullName: e.first_name,*/}
                                                {/*                                    lname: e.last_name,*/}
                                                {/*                                    city: e.city,*/}
                                                {/*                                    street: e.address1,*/}
                                                {/*                                    // email: e.email,*/}

                                                {/*                                    phone: e.phone,*/}
                                                {/*                                });*/}
                                                {/*                            }}*/}
                                                {/*                        />*/}
                                                {/*                        <span className="checkmark"></span>*/}
                                                {/*                    </label>*/}
                                                {/*                </div>*/}
                                                {/*            );*/}
                                                {/*        })}*/}
                                                {/*</div>*/}
                                                {/*<div className="form-group">*/}
                                                {/*    <div className="form-check">*/}
                                                {/*        <span*/}
                                                {/*            onClick={this.openAddress}*/}
                                                {/*            className="form-check-input input-check"*/}
                                                {/*        >*/}
                                                {/*            <span className="input-check__body">*/}
                                                {/*                <input*/}
                                                {/*                    className="input-check__input"*/}
                                                {/*                    type="checkbox"*/}

                                                {/*                    id="checkout-different-address"*/}
                                                {/*                />*/}

                                                {/*                <span className="input-check__box" />*/}
                                                {/*                <Check9x7Svg className="input-check__icon" />*/}
                                                {/*            </span>*/}
                                                {/*        </span>*/}
                                                {/*        <label>*/}
                                                {/*            <FormattedMessage*/}
                                                {/*                id="chekout.shippingDifferent"*/}
                                                {/*                defaultMessage="Ship to a different address?"*/}
                                                {/*            />*/}
                                                {/*        </label>*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}

                                                <div className="form-group">
                                                    <FormattedMessage id="checkout.fullName" defaultMessage="fullName">
                                                        {(placeholder) => (
                                                            <input
                                                                onChange={this.handleChange}
                                                                value={this.state.name}
                                                                type="text"
                                                                name="name"
                                                                className="form-control"
                                                                id="checkout-first-name"
                                                                placeholder={placeholder + " *"}
                                                            />
                                                        )}
                                                    </FormattedMessage>
                                                    {errors.name.length > 0 && (
                                                        <span className="alert-danger">
                                                                <FormattedMessage
                                                                    id="account.error.required"
                                                                    defaultMessage={errors.name}
                                                                />
                                                            </span>
                                                    )}
                                                </div>
                                                {(!this.state.customer.authenticated || this.state.customer.authenticated && this.state.customer.role === "Manager") &&
                                                    <div className="form-group">
                                                        <FormattedMessage id="checkout.companyName"
                                                                          defaultMessage="company name">
                                                            {(placeholder) => (
                                                                <input
                                                                    onChange={this.handleChange}
                                                                    value={this.state.shipingCompanyName}
                                                                    type="text"
                                                                    name="shipingCompanyName"
                                                                    className="form-control"
                                                                    id="checkout-company-name"
                                                                    placeholder={this.state.customer.authenticated && this.state.customer.role === "Manager" ? placeholder + " *" : placeholder}
                                                                />
                                                            )}
                                                        </FormattedMessage>
                                                        {errors.shipingCompanyName.length > 0 && (
                                                            <span className="alert-danger">
                                                                <FormattedMessage
                                                                    id="account.error.required"
                                                                    defaultMessage={errors.shipingCompanyName}
                                                                />
                                                            </span>
                                                        )}
                                                    </div>
                                                }
                                                {/*<div className="form-row">*/}
                                                {/*    <div className="form-group col-md-6">*/}
                                                {/*        <FormattedMessage id="checkout.name" defaultMessage="Name">*/}
                                                {/*            {(placeholder) => (*/}
                                                {/*                <input*/}
                                                {/*                    onChange={this.handleChange}*/}
                                                {/*                    value={this.state.name}*/}
                                                {/*                    type="text"*/}
                                                {/*                    name="name"*/}
                                                {/*                    className="form-control"*/}
                                                {/*                    id="checkout-first-name"*/}
                                                {/*                    placeholder={placeholder}*/}
                                                {/*                />*/}
                                                {/*            )}*/}
                                                {/*        </FormattedMessage>*/}
                                                {/*        {errors.name.length > 0 && (*/}
                                                {/*            <span className="alert-danger">*/}
                                                {/*                <FormattedMessage*/}
                                                {/*                    id="account.error.required"*/}
                                                {/*                    defaultMessage={errors.name}*/}
                                                {/*                />*/}
                                                {/*            </span>*/}
                                                {/*        )}*/}
                                                {/*    </div>*/}

                                                {/*    <div className="form-group col-md-6">*/}
                                                {/*        <FormattedMessage*/}
                                                {/*            id="checkout.lname"*/}
                                                {/*            defaultMessage="Last Name"*/}
                                                {/*        >*/}
                                                {/*            {(placeholder) => (*/}
                                                {/*                <input*/}
                                                {/*                    onChange={this.handleChange}*/}
                                                {/*                    value={this.state.shipingLname}*/}
                                                {/*                    type="text"*/}
                                                {/*                    name="shipingLname"*/}
                                                {/*                    className="form-control"*/}
                                                {/*                    id="checkout-first-name"*/}
                                                {/*                    placeholder={placeholder}*/}
                                                {/*                />*/}
                                                {/*            )}*/}
                                                {/*        </FormattedMessage>*/}
                                                {/*        {errors.shipingLname.length > 0 && (*/}
                                                {/*            <span className="alert-danger">*/}
                                                {/*                <FormattedMessage*/}
                                                {/*                    id="account.error.required"*/}
                                                {/*                    defaultMessage={errors.shipingLname}*/}
                                                {/*                />*/}
                                                {/*            </span>*/}
                                                {/*        )}*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}
                                                {/*<div className="form-row">*/}
                                                {/*    <div className="form-group col-md-6">*/}
                                                {/*        <FormattedMessage id="global.country" defaultMessage="Country">*/}
                                                {/*            {(placeholder) => (*/}
                                                {/*                <input*/}
                                                {/*                    onChange={this.handleChange}*/}
                                                {/*                    value={this.state.country}*/}
                                                {/*                    type="text"*/}
                                                {/*                    name="country"*/}
                                                {/*                    className="form-control"*/}
                                                {/*                    id="checkout-country"*/}
                                                {/*                    placeholder={placeholder}*/}
                                                {/*                    disabled="disabled"*/}
                                                {/*                />*/}
                                                {/*            )}*/}
                                                {/*        </FormattedMessage>*/}
                                                {/*    </div>*/}

                                                {/*    <div className="form-group col-md-6">*/}
                                                {/*        <FormattedMessage id="global.city" defaultMessage="City">*/}
                                                {/*            {(placeholder) => (*/}
                                                {/*                <input*/}
                                                {/*                    onChange={this.handleChange}*/}
                                                {/*                    value={this.state.shipingCity}*/}
                                                {/*                    type="text"*/}
                                                {/*                    name="shipingCity"*/}
                                                {/*                    className="form-control"*/}
                                                {/*                    id="checkout-city"*/}
                                                {/*                    placeholder={placeholder}*/}
                                                {/*                />*/}
                                                {/*            )}*/}
                                                {/*        </FormattedMessage>*/}
                                                {/*        {errors.shipingCity.length > 0 && (*/}
                                                {/*            <span className="alert-danger">*/}
                                                {/*                <FormattedMessage*/}
                                                {/*                    id="account.error.required"*/}
                                                {/*                    defaultMessage={errors.shipingCity}*/}
                                                {/*                />*/}
                                                {/*            </span>*/}
                                                {/*        )}*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}

                                                <div className="form-group">
                                                    <FormattedMessage id="address" defaultMessage="Address">
                                                        {(placeholder) => (
                                                            <input
                                                                onChange={this.handleChange}
                                                                value={this.state.shipingStreet}
                                                                name="shipingStreet"
                                                                type="text"
                                                                className="form-control"
                                                                id="checkout-street"
                                                                placeholder={placeholder + " *"}
                                                            />
                                                        )}
                                                    </FormattedMessage>

                                                    {errors.shipingStreet.length > 0 && (
                                                        <span className="alert-danger">
                                                            <FormattedMessage
                                                                id="account.error.required"
                                                                defaultMessage={errors.shipingStreet}
                                                            />
                                                        </span>
                                                    )}
                                                </div>
                                                <div className="form-group">
                                                    <FormattedMessage
                                                        id="global.phoneNumber"
                                                        defaultMessage="Phone number"
                                                    >
                                                        {(placeholder) => (
                                                            <input
                                                                onChange={this.handleChange}
                                                                value={this.state.shipingPhone}
                                                                name="shipingPhone"
                                                                type="text"
                                                                className="form-control"
                                                                id="checkout-phone"
                                                                placeholder={placeholder + " *"}
                                                            />
                                                        )}
                                                    </FormattedMessage>
                                                    {errors.shipingPhone.length > 0 && (
                                                        <span className="alert-danger">
                                                                <FormattedMessage
                                                                    id="account.error.required"
                                                                    defaultMessage={errors.shipingPhone}
                                                                />
                                                            </span>
                                                    )}
                                                </div>
                                                {/*<div className="form-row">*/}
                                                {/*    <div className="form-group col-md-6">*/}
                                                {/*        <FormattedMessage id="global.email" defaultMessage="Email">*/}
                                                {/*            {(placeholder) => (*/}
                                                {/*                <input*/}
                                                {/*                    // value={this.state.shipingEmail}*/}
                                                {/*                    value={this.state.email}*/}
                                                {/*                    onChange={this.handleChange}*/}
                                                {/*                    type="shipingEmail"*/}
                                                {/*                    name="email"*/}
                                                {/*                    className="form-control"*/}
                                                {/*                    id="checkout-email"*/}
                                                {/*                    placeholder={placeholder}*/}
                                                {/*                    disabled={true}*/}
                                                {/*                />*/}
                                                {/*            )}*/}
                                                {/*        </FormattedMessage>*/}
                                                {/*        /!*{errors.shipingEmail.length > 0 && (*!/*/}
                                                {/*        {errors.email.length > 0 && (*/}
                                                {/*            <span className="alert-danger">*/}
                                                {/*                <FormattedMessage*/}
                                                {/*                    id="account.error.required"*/}
                                                {/*                    defaultMessage={errors.shipingEmail}*/}
                                                {/*                />*/}
                                                {/*            </span>*/}
                                                {/*        )}*/}
                                                {/*    </div>*/}
                                                {/*    <div className="form-group col-md-6">*/}
                                                {/*        <FormattedMessage*/}
                                                {/*            id="global.phone"*/}
                                                {/*            defaultMessage="Phone number"*/}
                                                {/*        >*/}
                                                {/*            {(placeholder) => (*/}
                                                {/*                <input*/}
                                                {/*                    onChange={this.handleChange}*/}
                                                {/*                    value={this.state.shipingPhone}*/}
                                                {/*                    name="shipingPhone"*/}
                                                {/*                    type="text"*/}
                                                {/*                    className="form-control"*/}
                                                {/*                    id="checkout-phone"*/}
                                                {/*                    placeholder={placeholder}*/}
                                                {/*                />*/}
                                                {/*            )}*/}
                                                {/*        </FormattedMessage>*/}
                                                {/*        {errors.shipingPhone.length > 0 && (*/}
                                                {/*            <span className="alert-danger">*/}
                                                {/*                <FormattedMessage*/}
                                                {/*                    id="account.error.required"*/}
                                                {/*                    defaultMessage={errors.shipingPhone}*/}
                                                {/*                />*/}
                                                {/*            </span>*/}
                                                {/*        )}*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}
                                            </>
                                        ) : (
                                            ""
                                        )}
                                        <div className="form-group">
                                            <label htmlFor="checkout-comment">
                                                <FormattedMessage id="checkout.notes" defaultMessage="Order notes"/>
                                            </label>
                                            <textarea
                                                onChange={this.handleChange}
                                                name="notes"
                                                id="checkout-comment"
                                                className="form-control"
                                                rows="4"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-lg-6 col-xl-5 mt-4 mt-lg-0">
                                <div className="card mb-0">
                                    <div className="card-body">
                                        <h3 className="card-title">
                                            {" "}
                                            <FormattedMessage id="order" defaultMessage="Your Order"/>
                                        </h3>

                                        {this.renderCart()}

                                        {this.renderPaymentsList()}

                                        <div className="checkout__agree form-group">
                                            <div className="form-check">
                                                <span className="form-check-input input-check">
                                                    <span className="input-check__body">
                                                        <input
                                                            onChange={this.handleChange}
                                                            className="input-check__input"
                                                            type="checkbox"
                                                            id="checkout-check"
                                                            name="checkboxCheck"
                                                        />
                                                        <span className="input-check__box"/>
                                                        <Check9x7Svg className="input-check__icon"/>
                                                    </span>
                                                </span>

                                                <label className="form-check-label" htmlFor="checkout-terms">
                                                    <FormattedMessage
                                                        id="checkout_order_agrement_text"
                                                        defaultMessage="I agree with"
                                                    />
                                                    <Link to={`/${locale}/page/4`} target="_blank">
                                                        <span>
                                                            {" "}<FormattedMessage
                                                                id="checkout_order_term"
                                                                defaultMessage="I agree with"
                                                            />
                                                        </span>
                                                    </Link>
                                                    <span style={{color: "red"}}>*</span>
                                                </label>
                                                {errors.checkboxCheck.length > 0 && (
                                                    <span className="alert-danger">
                                                        {" "}
                                                        <FormattedMessage
                                                            id="account.error.required"
                                                            defaultMessage={errors.checkboxCheck}
                                                        />
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="delivery_information">
                                            <InfoSvg/>
                                            <p>
                                                <FormattedMessage
                                                    id="shipping_note_text"
                                                    defaultMessage="Delivery information"
                                                />
                                            </p>
                                        </div>
                                        <button
                                            onClick={this.handleSubmit}
                                            type="submit"
                                            className={
                                                !this.state.loading
                                                    ? "check_butt_fms  btn btn-primary"
                                                    : "check_butt_fms  btn btn-primary btn-loading"
                                            }
                                        >
                                            <FormattedMessage id="order.button" defaultMessage="Վճարել"/>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    cart: state.cart,
    locale: state.locale,
    customer: state.customer,
});

const mapDispatchToProps = {};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ShopPageCheckout));
