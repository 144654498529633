// react
import React, {Component} from "react";
import ProductModal from "./ProductModal";
import InputNumber from "../shared/InputNumber";
import AsyncAction from "../shared/AsyncAction";
import PropTypes from "prop-types";
import {cartAddItemNew} from "../../store/cart";
import {wishlistAddItem} from "../../store/wishlist";
import classNames from "classnames";
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";
import product from "../shared/Product";
import {setLastScrollPosition} from "../../store/general";
import {BoxIcon} from "../../svg";

class Product extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modal: false,
            quantity: Boolean(this.props.product.min_qty) ? Number(this.props.product.min_qty) : 1,
            customer: this.props.customer,
            wishlist: this.props.wishlist,
        };
    }

    getMaxQty = (product) => {
        return Math.max(...product.variants.map(el => el.qty))
    }

    handleChangeQuantity = (quantity) => {
        const productQty = this.props.product.type !== "configurable" ? this.props.product.qty : this.getMaxQty(this.props.product)
        this.setState({
            quantity: quantity > productQty ? productQty : quantity
        });
    };

    render() {
        const {
            product,
            // layout,
            wishlistAddItem,
            // compareAddItem,
            cartAddItemNew,
            locale,
        } = this.props;

        const {quantity, modal, customer} = this.state;

        let configPrice = 0, configOldPrice = 0
        if (product?.type === "configurable" && product?.variants.length > 0) {
            configPrice = product?.variants?.reduce((max, min) => Number(max.price) < Number(min.price) ? max : min).price
            configOldPrice = product?.variants?.reduce((max, min) => Number(max.price) < Number(min.price) ? max : min).old_price
        }

        let minQty = Boolean(product.min_qty) ? Number(product.min_qty) : 1;
        const wishListStorage = JSON.parse(localStorage.getItem("state"));
        if (wishListStorage.wishlist.length) {
            if (wishListStorage.wishlist.filter((e) => e.id === product.id).length)
                Object.assign(product, {wishlist: true});
            else {
                Object.assign(product, {wishlist: false});
            }
        } else {
            Object.assign(product, {wishlist: false});
        }
        // url
        // const modalFunc = () => {
        //     this.setState({modal: !modal});
        // };

        const closeFunc = () => {
            this.setState({modal: false});
        };

        return (
            <div className="block-brands__item">
                {/*<ProductModal*/}
                {/*    modal={modal}*/}
                {/*    modalFunc={this.props.modalFunc ? this.props.modalFunc : closeFunc}*/}
                {/*    data={product}*/}
                {/*/>*/}
                <div className="item_fm" onClick={() => this.props.setLastScrollPosition(window.scrollY)}>
                    <div className="product_image_wrapper">
                        <Link to={`/${locale}/shop/products/${product.url_key}`}>
                            <div
                                className="img_fm"
                            >
                                <img src={product?.base_image?.original_image_url || product?.image} alt=""/>
                            </div>
                        </Link>
                        {product.box_price && product.type !== "configurable" &&
                            <div className="product__box_price">
                                <BoxIcon/>
                                <span className="box_price">-{(product.price * product.box_qty) - product.box_price}
                                    <FormattedMessage id="global.curency" defaultMessage="AMD"/>
                            </span>
                            </div>
                        }
                        <AsyncAction
                            action={() => {
                                return wishlistAddItem(
                                    product,
                                    customer.customer.token ? customer.customer.token : false,
                                    product.wishlist
                                ).then((e) => {
                                    if (e === false)
                                        toast.success(
                                            <FormattedMessage
                                                id="please.login"
                                                defaultMessage="`Please login or register in order to add to favorites.`"
                                            />
                                        );
                                });
                            }}
                            render={({run, loading}) => (
                                <button
                                    type="button"
                                    onClick={run}
                                    className={`wishList_button_fm ${classNames({
                                        "btn-loading": loading,
                                    })}`}
                                >
                                    {!product.wishlist ? (
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="22.48"
                                            height="20"
                                            viewBox="0 0 22.48 20"
                                        >
                                            <path
                                                id="heart"
                                                d="M11.24,20a1.318,1.318,0,0,1-.869-.327c-.908-.794-1.784-1.54-2.556-2.2l0,0A47.539,47.539,0,0,1,2.23,12.233,8.367,8.367,0,0,1,0,6.756a7.026,7.026,0,0,1,1.783-4.8A6.045,6.045,0,0,1,6.28,0,5.654,5.654,0,0,1,9.812,1.219,7.226,7.226,0,0,1,11.24,2.71a7.227,7.227,0,0,1,1.428-1.491A5.654,5.654,0,0,1,16.2,0a6.045,6.045,0,0,1,4.5,1.957,7.026,7.026,0,0,1,1.783,4.8,8.366,8.366,0,0,1-2.229,5.477,47.534,47.534,0,0,1-5.581,5.238c-.774.659-1.651,1.407-2.561,2.2A1.319,1.319,0,0,1,11.24,20ZM6.28,1.317A4.743,4.743,0,0,0,2.751,2.85,5.716,5.716,0,0,0,1.317,6.756a7.04,7.04,0,0,0,1.927,4.637,46.782,46.782,0,0,0,5.421,5.077l0,0c.775.661,1.654,1.41,2.569,2.21.92-.8,1.8-1.552,2.578-2.214a46.793,46.793,0,0,0,5.421-5.076,7.041,7.041,0,0,0,1.927-4.637A5.716,5.716,0,0,0,19.729,2.85,4.742,4.742,0,0,0,16.2,1.317a4.367,4.367,0,0,0-2.727.944,6.39,6.39,0,0,0-1.52,1.758.831.831,0,0,1-1.428,0,6.384,6.384,0,0,0-1.52-1.758A4.367,4.367,0,0,0,6.28,1.317Zm0,0"
                                                transform="translate(0 0)"
                                                fill="#b0c472"
                                            />
                                        </svg>
                                    ) : (
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="22.48"
                                            height="20"
                                            viewBox="0 0 22.48 20"
                                        >
                                            <g
                                                id="Сгруппировать_68760"
                                                data-name="Сгруппировать 68760"
                                                transform="translate(16954 18908)"
                                            >
                                                <path
                                                    id="heart"
                                                    d="M11.24,20a1.318,1.318,0,0,1-.869-.327c-.908-.794-1.784-1.54-2.556-2.2l0,0A47.539,47.539,0,0,1,2.23,12.233,8.367,8.367,0,0,1,0,6.756a7.026,7.026,0,0,1,1.783-4.8A6.045,6.045,0,0,1,6.28,0,5.654,5.654,0,0,1,9.812,1.219,7.226,7.226,0,0,1,11.24,2.71a7.227,7.227,0,0,1,1.428-1.491A5.654,5.654,0,0,1,16.2,0a6.045,6.045,0,0,1,4.5,1.957,7.026,7.026,0,0,1,1.783,4.8,8.366,8.366,0,0,1-2.229,5.477,47.534,47.534,0,0,1-5.581,5.238c-.774.659-1.651,1.407-2.561,2.2A1.319,1.319,0,0,1,11.24,20ZM.689,6.3c-1.364,0,8.505-.531,7.594.457A24,24,0,0,1,14.67,1.219c1.718-.9,4.074.161,4.074.738,0,.285.776,0,1.506.753A6.774,6.774,0,0,1,21.535,6.3a13.808,13.808,0,0,1-1.285,4.727c-.457.722-.927-.489-.522,0,1.288,1.554-10.524,4.329-2.3-.387,3.611,4.536-4.389,6.492-3.612,5.83a46.793,46.793,0,0,0,5.421-5.076,7.041,7.041,0,0,0,1.927-4.637A5.716,5.716,0,0,0,19.729,2.85,4.742,4.742,0,0,0,16.2,1.317a4.367,4.367,0,0,0-2.727.944c-.722.558,1.491-1.536,1.2-1.042-.152.254-6.091,9.806-6.387,9.806C19.434,4.721,8,16,7.275,15.443,6.465,14.817,1.689,6.3.689,6.3Z"
                                                    transform="translate(-16954 -18908)"
                                                    fill="#43b02a"
                                                />
                                            </g>
                                        </svg>
                                    )}
                                </button>
                            )}
                        />
                    </div>
                    <div className="block_bott_fm">
                        <Link to={`/${locale}/shop/products/${product.url_key}`}>
                            <div
                                className="item_name_fm"
                                // onClick={() => {
                                //     modalFunc();
                                // }}
                            >
                                {product.name}
                            </div>
                        </Link>
                        {product.company_price ? (
                            <div className="price_fm">
                                {Math.round(product.company_price)}{" "}
                                <FormattedMessage id="global.curency" defaultMessage="AMD"/>
                                {/*<AsyncAction*/}
                                {/*    action={() => {*/}
                                {/*        return wishlistAddItem(*/}
                                {/*            product,*/}
                                {/*            customer.customer.token ? customer.customer.token : false,*/}
                                {/*            product.wishlist*/}
                                {/*        ).then((e) => {*/}
                                {/*            if (e === false)*/}
                                {/*                toast.success(*/}
                                {/*                    <FormattedMessage*/}
                                {/*                        id="please.login"*/}
                                {/*                        defaultMessage="`Please login or register in order to add to favorites.`"*/}
                                {/*                    />*/}
                                {/*                );*/}
                                {/*        });*/}
                                {/*    }}*/}
                                {/*    render={({run, loading}) => (*/}
                                {/*        <button*/}
                                {/*            type="button"*/}
                                {/*            onClick={run}*/}
                                {/*            className={`wishList_button_fm ${classNames({*/}
                                {/*                "btn-loading": loading,*/}
                                {/*            })}`}*/}
                                {/*        >*/}
                                {/*            {!product.wishlist ? (*/}
                                {/*                <svg*/}
                                {/*                    xmlns="http://www.w3.org/2000/svg"*/}
                                {/*                    width="22.48"*/}
                                {/*                    height="20"*/}
                                {/*                    viewBox="0 0 22.48 20"*/}
                                {/*                >*/}
                                {/*                    <path*/}
                                {/*                        id="heart"*/}
                                {/*                        d="M11.24,20a1.318,1.318,0,0,1-.869-.327c-.908-.794-1.784-1.54-2.556-2.2l0,0A47.539,47.539,0,0,1,2.23,12.233,8.367,8.367,0,0,1,0,6.756a7.026,7.026,0,0,1,1.783-4.8A6.045,6.045,0,0,1,6.28,0,5.654,5.654,0,0,1,9.812,1.219,7.226,7.226,0,0,1,11.24,2.71a7.227,7.227,0,0,1,1.428-1.491A5.654,5.654,0,0,1,16.2,0a6.045,6.045,0,0,1,4.5,1.957,7.026,7.026,0,0,1,1.783,4.8,8.366,8.366,0,0,1-2.229,5.477,47.534,47.534,0,0,1-5.581,5.238c-.774.659-1.651,1.407-2.561,2.2A1.319,1.319,0,0,1,11.24,20ZM6.28,1.317A4.743,4.743,0,0,0,2.751,2.85,5.716,5.716,0,0,0,1.317,6.756a7.04,7.04,0,0,0,1.927,4.637,46.782,46.782,0,0,0,5.421,5.077l0,0c.775.661,1.654,1.41,2.569,2.21.92-.8,1.8-1.552,2.578-2.214a46.793,46.793,0,0,0,5.421-5.076,7.041,7.041,0,0,0,1.927-4.637A5.716,5.716,0,0,0,19.729,2.85,4.742,4.742,0,0,0,16.2,1.317a4.367,4.367,0,0,0-2.727.944,6.39,6.39,0,0,0-1.52,1.758.831.831,0,0,1-1.428,0,6.384,6.384,0,0,0-1.52-1.758A4.367,4.367,0,0,0,6.28,1.317Zm0,0"*/}
                                {/*                        transform="translate(0 0)"*/}
                                {/*                        fill="#b0c472"*/}
                                {/*                    />*/}
                                {/*                </svg>*/}
                                {/*            ) : (*/}
                                {/*                <svg*/}
                                {/*                    xmlns="http://www.w3.org/2000/svg"*/}
                                {/*                    width="22.48"*/}
                                {/*                    height="20"*/}
                                {/*                    viewBox="0 0 22.48 20"*/}
                                {/*                >*/}
                                {/*                    <g*/}
                                {/*                        id="Сгруппировать_68760"*/}
                                {/*                        data-name="Сгруппировать 68760"*/}
                                {/*                        transform="translate(16954 18908)"*/}
                                {/*                    >*/}
                                {/*                        <path*/}
                                {/*                            id="heart"*/}
                                {/*                            d="M11.24,20a1.318,1.318,0,0,1-.869-.327c-.908-.794-1.784-1.54-2.556-2.2l0,0A47.539,47.539,0,0,1,2.23,12.233,8.367,8.367,0,0,1,0,6.756a7.026,7.026,0,0,1,1.783-4.8A6.045,6.045,0,0,1,6.28,0,5.654,5.654,0,0,1,9.812,1.219,7.226,7.226,0,0,1,11.24,2.71a7.227,7.227,0,0,1,1.428-1.491A5.654,5.654,0,0,1,16.2,0a6.045,6.045,0,0,1,4.5,1.957,7.026,7.026,0,0,1,1.783,4.8,8.366,8.366,0,0,1-2.229,5.477,47.534,47.534,0,0,1-5.581,5.238c-.774.659-1.651,1.407-2.561,2.2A1.319,1.319,0,0,1,11.24,20ZM.689,6.3c-1.364,0,8.505-.531,7.594.457A24,24,0,0,1,14.67,1.219c1.718-.9,4.074.161,4.074.738,0,.285.776,0,1.506.753A6.774,6.774,0,0,1,21.535,6.3a13.808,13.808,0,0,1-1.285,4.727c-.457.722-.927-.489-.522,0,1.288,1.554-10.524,4.329-2.3-.387,3.611,4.536-4.389,6.492-3.612,5.83a46.793,46.793,0,0,0,5.421-5.076,7.041,7.041,0,0,0,1.927-4.637A5.716,5.716,0,0,0,19.729,2.85,4.742,4.742,0,0,0,16.2,1.317a4.367,4.367,0,0,0-2.727.944c-.722.558,1.491-1.536,1.2-1.042-.152.254-6.091,9.806-6.387,9.806C19.434,4.721,8,16,7.275,15.443,6.465,14.817,1.689,6.3.689,6.3Z"*/}
                                {/*                            transform="translate(-16954 -18908)"*/}
                                {/*                            fill="#43b02a"*/}
                                {/*                        />*/}
                                {/*                    </g>*/}
                                {/*                </svg>*/}
                                {/*            )}*/}
                                {/*        </button>*/}
                                {/*    )}*/}
                                {/*/>*/}
                            </div>
                        ) : product.old_price || configOldPrice ? (
                            <>
                                <div className="price_fm">
                                    <span>
                                        {Math.round(configPrice || product.price)}{" "}
                                        <FormattedMessage id="global.curency" defaultMessage="AMD"/>
                                    </span>
                                    <span className="dis_price_fm">
                                        {Math.round(configOldPrice || product.old_price)}{" "}
                                        <FormattedMessage id="global.curency" defaultMessage="AMD"/>
                                    </span>
                                    <span style={{flexBasis: "100%"}} className="buy__more">
                                        {product.box_price && product.type !== "configurable" &&
                                             <FormattedMessage
                                                id="buy_more_save_more"
                                                defaultMessage="Buy more, save more"
                                             />
                                        }
                                    </span>
                                    {/*<AsyncAction*/}
                                    {/*    action={() => {*/}
                                    {/*        return wishlistAddItem(*/}
                                    {/*            product,*/}
                                    {/*            customer.customer.token ? customer.customer.token : false,*/}
                                    {/*            product.wishlist*/}
                                    {/*        ).then((e) => {*/}
                                    {/*            if (e === false)*/}
                                    {/*                toast.success(*/}
                                    {/*                    <FormattedMessage*/}
                                    {/*                        id="please.login"*/}
                                    {/*                        defaultMessage="`Please login or register in order to add to favorites.`"*/}
                                    {/*                    />*/}
                                    {/*                );*/}
                                    {/*        });*/}
                                    {/*    }}*/}
                                    {/*    render={({run, loading}) => (*/}
                                    {/*        <button*/}
                                    {/*            type="button"*/}
                                    {/*            onClick={run}*/}
                                    {/*            className={`wishList_button_fm ${classNames({*/}
                                    {/*                "btn-loading": loading,*/}
                                    {/*            })}`}*/}
                                    {/*        >*/}
                                    {/*            {!product.wishlist ? (*/}
                                    {/*                <svg*/}
                                    {/*                    xmlns="http://www.w3.org/2000/svg"*/}
                                    {/*                    width="22.48"*/}
                                    {/*                    height="20"*/}
                                    {/*                    viewBox="0 0 22.48 20"*/}
                                    {/*                >*/}
                                    {/*                    <path*/}
                                    {/*                        id="heart"*/}
                                    {/*                        d="M11.24,20a1.318,1.318,0,0,1-.869-.327c-.908-.794-1.784-1.54-2.556-2.2l0,0A47.539,47.539,0,0,1,2.23,12.233,8.367,8.367,0,0,1,0,6.756a7.026,7.026,0,0,1,1.783-4.8A6.045,6.045,0,0,1,6.28,0,5.654,5.654,0,0,1,9.812,1.219,7.226,7.226,0,0,1,11.24,2.71a7.227,7.227,0,0,1,1.428-1.491A5.654,5.654,0,0,1,16.2,0a6.045,6.045,0,0,1,4.5,1.957,7.026,7.026,0,0,1,1.783,4.8,8.366,8.366,0,0,1-2.229,5.477,47.534,47.534,0,0,1-5.581,5.238c-.774.659-1.651,1.407-2.561,2.2A1.319,1.319,0,0,1,11.24,20ZM6.28,1.317A4.743,4.743,0,0,0,2.751,2.85,5.716,5.716,0,0,0,1.317,6.756a7.04,7.04,0,0,0,1.927,4.637,46.782,46.782,0,0,0,5.421,5.077l0,0c.775.661,1.654,1.41,2.569,2.21.92-.8,1.8-1.552,2.578-2.214a46.793,46.793,0,0,0,5.421-5.076,7.041,7.041,0,0,0,1.927-4.637A5.716,5.716,0,0,0,19.729,2.85,4.742,4.742,0,0,0,16.2,1.317a4.367,4.367,0,0,0-2.727.944,6.39,6.39,0,0,0-1.52,1.758.831.831,0,0,1-1.428,0,6.384,6.384,0,0,0-1.52-1.758A4.367,4.367,0,0,0,6.28,1.317Zm0,0"*/}
                                    {/*                        transform="translate(0 0)"*/}
                                    {/*                        fill="#b0c472"*/}
                                    {/*                    />*/}
                                    {/*                </svg>*/}
                                    {/*            ) : (*/}
                                    {/*                <svg*/}
                                    {/*                    xmlns="http://www.w3.org/2000/svg"*/}
                                    {/*                    width="22.48"*/}
                                    {/*                    height="20"*/}
                                    {/*                    viewBox="0 0 22.48 20"*/}
                                    {/*                >*/}
                                    {/*                    <g*/}
                                    {/*                        id="Сгруппировать_68760"*/}
                                    {/*                        data-name="Сгруппировать 68760"*/}
                                    {/*                        transform="translate(16954 18908)"*/}
                                    {/*                    >*/}
                                    {/*                        <path*/}
                                    {/*                            id="heart"*/}
                                    {/*                            d="M11.24,20a1.318,1.318,0,0,1-.869-.327c-.908-.794-1.784-1.54-2.556-2.2l0,0A47.539,47.539,0,0,1,2.23,12.233,8.367,8.367,0,0,1,0,6.756a7.026,7.026,0,0,1,1.783-4.8A6.045,6.045,0,0,1,6.28,0,5.654,5.654,0,0,1,9.812,1.219,7.226,7.226,0,0,1,11.24,2.71a7.227,7.227,0,0,1,1.428-1.491A5.654,5.654,0,0,1,16.2,0a6.045,6.045,0,0,1,4.5,1.957,7.026,7.026,0,0,1,1.783,4.8,8.366,8.366,0,0,1-2.229,5.477,47.534,47.534,0,0,1-5.581,5.238c-.774.659-1.651,1.407-2.561,2.2A1.319,1.319,0,0,1,11.24,20ZM.689,6.3c-1.364,0,8.505-.531,7.594.457A24,24,0,0,1,14.67,1.219c1.718-.9,4.074.161,4.074.738,0,.285.776,0,1.506.753A6.774,6.774,0,0,1,21.535,6.3a13.808,13.808,0,0,1-1.285,4.727c-.457.722-.927-.489-.522,0,1.288,1.554-10.524,4.329-2.3-.387,3.611,4.536-4.389,6.492-3.612,5.83a46.793,46.793,0,0,0,5.421-5.076,7.041,7.041,0,0,0,1.927-4.637A5.716,5.716,0,0,0,19.729,2.85,4.742,4.742,0,0,0,16.2,1.317a4.367,4.367,0,0,0-2.727.944c-.722.558,1.491-1.536,1.2-1.042-.152.254-6.091,9.806-6.387,9.806C19.434,4.721,8,16,7.275,15.443,6.465,14.817,1.689,6.3.689,6.3Z"*/}
                                    {/*                            transform="translate(-16954 -18908)"*/}
                                    {/*                            fill="#43b02a"*/}
                                    {/*                        />*/}
                                    {/*                    </g>*/}
                                    {/*                </svg>*/}
                                    {/*            )}*/}
                                    {/*        </button>*/}
                                    {/*    )}*/}
                                    {/*/>*/}
                                </div>
                            </>
                        ) : (
                            <div className="price_fm price-old-fms">
                                <span>
                                    {Math.round(configPrice || product.price)}{" "}
                                    <FormattedMessage id="global.curency" defaultMessage="AMD"/>
                                </span>
                                <span className="buy__more">
                                    {product.box_price && product.type !== "configurable" &&
                                        <FormattedMessage
                                            id="buy_more_save_more"
                                            defaultMessage="Buy more, save more"
                                        />
                                    }
                                </span>
                            </div>
                        )}
                        <div className="product_buttons_container">
                            <InputNumber
                                id="product-quantity"
                                aria-label="Quantity"
                                className="product__quantity"
                                size="lg"
                                step={minQty}
                                min={minQty}
                                max={product.type !== "configurable" ? product.qty : this.getMaxQty(product)}
                                value={quantity}
                                disabled={product.type !== "configurable" && product.qty <= 0}
                                onChange={this.handleChangeQuantity}
                            />
                            <div className="add_card_fm product_add_card">
                                {product.type === "configurable" ? (
                                    <Link to={`/${locale}/shop/products/${product.url_key}`}>
                                        <button type="button"
                                            // onClick={() => modalFunc()}
                                        >
                                            <FormattedMessage id="add_to_cart" defaultMessage="Add to cart"/>
                                        </button>
                                    </Link>
                                ) : (
                                    <AsyncAction
                                        action={() =>
                                            cartAddItemNew(
                                                product,
                                                quantity,
                                            )
                                        }
                                        render={({run, loading}) => (
                                            <button
                                                type="button"
                                                onClick={run}
                                                disabled={product.qty <= 0}
                                                className={classNames({
                                                    "btn-loading": loading,
                                                    "disabled": product.qty <= 0,
                                                })}
                                            >
                                                <FormattedMessage id="add_to_cart" defaultMessage="Add to cart"/>
                                            </button>
                                        )}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Product.propTypes = {
    /** product object */
    product: PropTypes.object.isRequired,
    /** one of ['standard', 'sidebar', 'columnar', 'quickview'] (default: 'standard') */
    layout: PropTypes.oneOf(["standard", "sidebar", "columnar", "quickview"]),
};

Product.defaultProps = {
    layout: "standard",
};

const mapStateToProps = (state) => ({
    customer: state,
    wishlist: state.wishlist,
    locale: state.locale
});

const mapDispatchToProps = {
    cartAddItemNew,
    wishlistAddItem,
    setLastScrollPosition,
};

export default connect(mapStateToProps, mapDispatchToProps)(Product);
