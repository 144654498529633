// react
import React from "react";
import { Link } from "react-router-dom";
// data stubs
import SocialLinks from "../shared/SocialLinks";
import { FormattedMessage } from "react-intl";
import {useSelector} from "react-redux";

export default function FooterContacts(props) {
    const locale = useSelector(state => state.locale)


    const { id, page_id, name, url_key} = props;
    let urlCat = url_key ? url_key : `/${locale}/page/` + page_id;

    return (
        <span>
            <Link to={urlCat}>
                {name}
            </Link>
        </span>
    );
}
