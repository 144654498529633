// react
import React from 'react';
import Product from './Product'
import {FormattedMessage} from 'react-intl';
import Slider from "react-slick";
import {CarouselArrowLeft, CarouselArrowRight} from "../../svg";

const settings = {
    dots: false,
    infinite: false,
    speed: 400,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <CarouselArrowRight/>,
    prevArrow: <CarouselArrowLeft/>,
    responsive: [
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 499,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            },
        },
    ],
};

const RelatedItems = ({relatedProducts}) => {

    const brandsList = relatedProducts && relatedProducts.length ?
        relatedProducts.map(product => (
            <Product product={product} key={product.id}/>
        )) : null;

    return (
        <div className="related_items_fm">
            <div className="title_fm">
                <FormattedMessage id="related.items" defaultMessage="Related Items"/>
            </div>
            <div className="category_list_fm">
                <div className="cat_items_fm">
                    <Slider {...settings}>
                        {brandsList}
                    </Slider>
                </div>
            </div>
        </div>
    );
}

export default RelatedItems
