// react
import React, {useEffect, useReducer, useRef, useState} from "react";

// third-party
import PropTypes from "prop-types";
import queryString from "query-string";
import qs from "query-string";
import {connect, useDispatch, useSelector} from "react-redux";
import {Helmet} from "react-helmet-async";
// application
import PageHeader from "../shared/PageHeader";
import ProductsView from "./ProductsView";
import shopApi from "../../api/shop";
import {sidebarClose} from "../../store/sidebar";
import {useHistory, useLocation} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import {url,} from "../../services/utils";
import {setInitialMaxPrice, setInitialMinPrice, setLastScrollPosition} from "../../store/general/generalActions";
import WidgetFilters from "../widgets/WidgetFilters";
import ModalDialog from "../shared/ModalDialog";
import InfiniteScroll from 'react-infinite-scroll-component';
import Breadcrumbs from "../shared/Breadcrumbs";
import {VectorSvg} from "../../svg";

function parseQueryOptions(location) {
    const query = queryString.parse(location);
    const optionValues = {};

    if (typeof query.brand === "string") {
        optionValues.brand = query.brand;
    }

    if (typeof query.savings === "string") {
        optionValues.savings = query.savings;
    }
    if (typeof query.category_id === "string") {
        optionValues.category_id =
            typeof query.category_id === "number" ? parseInt(query.category_id) : query.category_id;
    }
    if (typeof query.search === "string") {
        optionValues.search = query.search;
    }

    if (typeof query.page === "string") {
        optionValues.page = parseFloat(query.page);
    }
    if (typeof query.limit === "string") {
        optionValues.limit = parseFloat(query.limit);
    }
    if (typeof query.sort === "string") {
        optionValues.sort = query.sort;
    }

    return optionValues;
}

function parseQueryFilters(location) {
    const query = queryString.parse(location);
    const filterValues = {};

    Object.keys(query).forEach((param) => {
        const mr = param.match(/^filter_([-_A-Za-z0-9]+)$/);

        if (!mr) {
            return;
        }

        const filterSlug = mr[1];

        filterValues[filterSlug] = query[param];
    });

    return filterValues;
}

function parseQuery(location) {
    return [parseQueryOptions(location), parseQueryFilters(location)];
}

function buildQuery(options, filters) {
    const params = {};

    if (options.savings !== "") {
        params.savings = options.savings;
    }
    if (options.brand !== "") {
        params.brand = options.brand;
    }

    if (options.search !== "") {
        params.search = options.search;
    }
    params.category_id = options.category_id;
    if (options.page !== 1) {
        params.page = options.page;
    }

    if (options.limit !== 12) {
        params.limit = options.limit;
    }

    if (options.sort !== "default") {
        params.sort = options.sort;
    }

    Object.keys(filters)
        .filter((x) => x !== "category" && !!filters[x])
        .forEach((filterSlug) => {
            params[`filter_${filterSlug}`] = filters[filterSlug];
        });

    return queryString.stringify(params, {encode: false});
}

const initialState = {
    init: false,
    /**
     * Indicates that the category is loading.
     */
    categoryIsLoading: true,
    /**
     * Category object.
     */
    category: null,
    /**
     * Indicates that the products list is loading.
     */
    productsListIsLoading: true,
    /**
     * Products list.
     */
    productsList: null,
    /**
     * Products list options.
     *
     * options.page:  number - Current page.
     * options.limit: number - Items per page.
     * options.sort:  string - Sort algorithm.
     */
    // options: {},
    /**
     * Products list filters.
     *
     * filters[FILTER_SLUG]: string - filter value.
     */
    filters: {},
};

function reducer(state, action) {
    switch (action.type) {
        case "FETCH_CATEGORY_SUCCESS":
            return {
                ...state,
                init: true,
                categoryIsLoading: false,
                category: action.category,
            };
        case "FETCH_PRODUCTS_LIST":
            return {...state, productsListIsLoading: true};
        case "FETCH_PRODUCTS_LIST_SUCCESS":
            return {...state, productsListIsLoading: false, productsList: action.productsList};
        case "SET_OPTION_VALUE":
            return {
                ...state,
                options: {...state.options, page: 1, [action.option]: action.value},
            };
        case "SET_FILTER_VALUE":
            return {
                ...state,
                options: {...state.options, page: 1},
                filters: {
                    ...state.filters,
                    [action.filter]:
                        state.filters[action.filter] && action.filter !== "price"
                            ? state.filters[action.filter] + (action.value ? "," + action.value : "")
                            : action.value,
                },
            };

        case "REMOVE_FILTER_VALUE":
            let dot = state.filters[action.filter].split(",");
            const index = dot.indexOf(action.value);
            if (index > -1) {
                dot.splice(index, 1);
            }
            dot = dot.join(",");

            return {
                ...state,
                options: {...state.options, page: 1},
                filters: {...state.filters, [action.filter]: dot},
            };
        case "SET_CAT_ID":

            return {
                ...state,
                options: {...state.options, category_id: action.value},
            };
        case "SET_SORT_BY_NAME":
            return {
                ...state,
                filters: {
                    ...state.filters,
                    sort: action.value.sort,
                    order: action.value.order
                },
            };
        case "RESET_FILTERS":
            // return {...state, options: {}, filters: {}};
            return {...state, filters: {}};
        case "RESET":
            return state.init ? initialState : state;
        default:
            throw new Error();
    }
}

function init(state) {
    const [options, filters] = parseQuery(window.location.search);

    return {...state, options, filters};
}

function ShopPageCategory(props) {
    const {
        catID,
        categorySlug,
        columns,
        viewMode,
        sidebarPosition,
        initialMinPrice,
        initialMaxPrice,
        setInitialMinPrice,
        setInitialMaxPrice,
    } = props;
    const offcanvas = columns === 3 ? "mobile" : "always";
    const [state, dispatch] = useReducer(reducer, initialState, init);
    const lastScrollPosition = useSelector(state => state.general.lastScrollPosition)
    const [scrollPosState, setScrollPosState] = useState(0)
    const location = useLocation();
    const globalDispatch = useDispatch();

    let cat = qs.parse(location.search);
    const [title, setTitle] = useState("");
    const [categories, setCategories] = useState([]);
    const [brands, setBrands] = useState([]);
    const locale = useSelector((state) => state.locale);
    const customer = useSelector((state) => state.customer)
    const [filtersData, setFilters] = useState([]);
    const [selectedCat, setSelectedCat] = useState({});
    const [selectedCategory, setSelectedCategory] = useState({});
    const [openDialog, setOpenDialog] = useState(false);
    const [ageAnswer, setAgeAnswer] = useState(null)
    const limit = 18
    const [currentPage, setCurrentPage] = useState(1)
    const [lastPage, setLastPage] = useState(1)
    const [productList, setProductList] = useState({data: []})
    const [category_id, setCategory_id] = useState(0)
    const [category_name, setCategory_name] = useState("")
    const [hasMore, setHasMore] = useState()
    const [sendRequest, setSendRequest] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [firstLoad, setFirstLoad] = useState(true)
    const [height, setHeight] = useState(0)
    const [isOpen, setIsOpen] = useState(false);
    const history = useHistory();
    const sortByRef = useRef(null)
    const api_token = localStorage.getItem("api_token")

    useEffect(() => {
        const closeWhenDocumentClick = (event) => {
            if (sortByRef.current && !sortByRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        if (isOpen) {
            document.addEventListener("click", closeWhenDocumentClick);
        }

        return () => {
            document.removeEventListener("click", closeWhenDocumentClick);
        };
    }, [isOpen]);

    useEffect(() => {
        setScrollPosState(lastScrollPosition)
        const scrollEvent = () => {
            if (window.scrollY !== 27)
                globalDispatch(setLastScrollPosition(window.scrollY))
        }

        document.addEventListener("scroll", scrollEvent)

        return () => document.removeEventListener("scroll", scrollEvent)
    }, []);

    useEffect(() => {
        setHeight(window.scrollY)
        if (!firstLoad && !isLoading && height < scrollPosState) {
            window.scrollTo(0, scrollPosState)
        }
        if (height === scrollPosState) {
            globalDispatch(setLastScrollPosition(0))
        }
    }, [firstLoad, isLoading, productList.data.length])

    const sortFiltersByPosition = (filters) => {
        return filters.map(filter => {
            return {
                ...filter,
                options: [...filter.options.sort((a, b) => a.sort_order - b.sort_order)]
            }
        })
    }

    useEffect(() => {
        setIsLoading(true)
    }, [state.filters]);

    useEffect(() => {
        const ageAnswerSession = JSON.parse(sessionStorage.getItem("addAgeAnswer"))
        setAgeAnswer(ageAnswerSession)
    }, [])

    const findSelectedCategory = (category_id, categories) => {
        let selectedCat

        for (let item of categories) {
            if (item.children.length) {
                selectedCat = findSelectedCategory(category_id, item.children)
                if (selectedCat) return selectedCat
            } else {
                if (item.id === +category_id) {
                    return item
                }
            }
        }

        return selectedCat
    }

    useEffect(() => {
        setSelectedCategory(findSelectedCategory(category_id, categories))
    }, [category_id, categories])

    useEffect(() => {
        if (!firstLoad) {
            dispatch({type: "RESET_FILTERS"})
        }
        setFirstLoad(false)
    }, [location.search]);

    useEffect(() => {
        setCurrentPage(1)
        setLastPage(1)
        // setHasMore(true)
        setHasMore(productList.data.length >= limit)
        setProductList({data: []})
    }, [location.search, state.filters]);

    useEffect(() => {
        let canceled = false;
        let selectedCatId = qs.parse(location.search).category_id;

        shopApi.getCategories({locale: locale}).then((categories) => {
            if (canceled) {
                return;
            }

            // categoryIDRef.current = selectedCatId
            setCategory_id(selectedCatId)
            dispatch({
                type: "SET_CAT_ID",
                value: selectedCatId,
            });
            setSendRequest(true)

            let categoriesData = categories?.categories;
            setCategories(categoriesData);

            let copied = [...categoriesData];
            copied.forEach((element) => {
                if (element?.children.length > 0) {
                    let children = element?.children
                    children.forEach((item) => {
                        if (item.id === Number(selectedCatId) || item.slug === "sports-drink") {
                            setSelectedCat(element);
                        }
                    });
                }
            });
        });

        shopApi.getCategoryByID(selectedCatId, locale)
            .then(res => {
                setTitle(res.meta_title)
            })


        return () => {
            canceled = true;
        };
    }, [locale, location.search]);

    useEffect(() => {
        const selectedCatId = qs.parse(location.search).category_id;
        if ((selectedCat.slug === "alcodepot" || selectedCat.slug === "alcohol" || selectedCatId === "325") && !Boolean(ageAnswer)) {
            setOpenDialog(true);
        } else {
            setOpenDialog(false);
        }
    }, [selectedCat, locale]);

    useEffect(() => {
        shopApi.getFilters({locale: locale, category_id: state.options.category_id}).then((e) => {
            if (e.data) {
                setFilters(sortFiltersByPosition(e.data.filter))
                setInitialMaxPrice(e.data.max_price);
                setInitialMinPrice(e.data.min_price);
            } else {
                setFilters(e.filter);
            }
        });
    }, [cat.category_id, location.search, categorySlug, catID, state.options.category_id]);

    useEffect(() => {
        const query = buildQuery(state.options, state.filters);
        const location = `${window.location.pathname}${query ? "?" : ""}${query}`;
        window.history.replaceState(null, "", location);
    }, [state.options, state.filters]);

    // Load products.
    useEffect(() => {
        const fetchData = async () => {
            let selectedCatId = qs.parse(location.search).category_id;
            if (currentPage <= lastPage) {
                return shopApi
                    .getProductsList({category_id: selectedCatId}, {
                            ...state.filters,
                            locale: locale,
                            token: customer.token || null,
                            limit: limit,
                            currentPage: currentPage,
                            api_token
                        },
                        location.search
                    )
                    .then((productsList) => {
                        dispatch({type: "FETCH_PRODUCTS_LIST_SUCCESS", productsList});

                        setFilters(sortFiltersByPosition(productsList.filter));

                        setProductList(prev => {
                            if (currentPage === 1) {
                                return productsList
                            } else if (prev.data.length < currentPage * limit) {
                                return {
                                    ...prev,
                                    data: [...prev.data, ...productsList.data]
                                }
                            }
                            return prev
                        })
                        // setHasMore(productList.data.length >= limit)

                        setHasMore(productsList.meta.current_page !== productsList.meta.last_page)
                        setLastPage(productsList.meta.last_page)
                        setIsLoading(false)
                    });
            } else setHasMore(false)
        }
        dispatch({type: "FETCH_PRODUCTS_LIST"});
        fetchData()

        // return () => {
        //     otherDispatch(removeCategoryName());
        // };
    }, [dispatch, location.search, state.filters, locale, currentPage]);


    const handleSelect = ({target: {id: targetId}}) => {
        const targetArr = targetId.split("=")
        dispatch({
            type: "SET_SORT_BY_NAME",
            value: {sort: targetArr[0], order: targetArr[1]},
        });

        setIsOpen(false);
    }


    const loadingBlock = (
        <div className="loading">
            <span> </span>
            <span> </span>
            <span> </span>
        </div>
    )

    if (state.productsListIsLoading && !state.productsList?.data.length) {
        // return <BlockLoader/>;
        return <div className="none_content">
            {loadingBlock}
        </div>;
    }

    let content;

    const setSavings = (e, type) => {
        e.preventDefault();
        let inp = document.getElementById("savings_fm_id");
        if (type === 0) {
            if (inp.checked === true)
                dispatch({
                    type: "SET_OPTION_VALUE",
                    option: "savings",
                    value: "",
                });
            else
                dispatch({
                    type: "SET_OPTION_VALUE",
                    option: "savings",
                    value: "true",
                });
        } else {
            if (inp.checked === false)
                dispatch({
                    type: "SET_OPTION_VALUE",
                    option: "savings",
                    value: "",
                });
            else
                dispatch({
                    type: "SET_OPTION_VALUE",
                    option: "savings",
                    value: "true",
                });
        }
    };

    const productsView = (
        isLoading
            ?
            <div className="loader_wrapper">
                {loadingBlock}
            </div>
            :
            productList.data.length > 0 ?
                <InfiniteScroll
                    dataLength={productList.data.length} //This is important field to render the next data
                    next={() => setCurrentPage(currentPage + 1)}
                    hasMore={hasMore}
                    loader={loadingBlock}
                    endMessage={
                        <p style={{textAlign: 'center'}}>
                            <b><FormattedMessage id="infinit.endmassage"
                                                 defaultMessage="Yay! You have seen it all"/></b>
                        </p>
                    }
                    pullDownToRefreshThreshold={0}

                >
                    <ProductsView
                        catID={catID}
                        categorySlug={categorySlug}
                        // isLoading={state.productsListIsLoading}
                        productsList={productList}
                        options={state.options}
                        filters={state.filters}
                        dispatch={dispatch}
                        layout={viewMode}
                        grid={`grid-${columns}-${columns > 3 ? "full" : "sidebar"}`}
                        offcanvas={offcanvas}
                    />
                </InfiniteScroll>
                :
                <ProductsView
                    catID={catID}
                    categorySlug={categorySlug}
                    // isLoading={state.productsListIsLoading}
                    productsList={productList}
                    options={state.options}
                    filters={state.filters}
                    dispatch={dispatch}
                    layout={viewMode}
                    grid={`grid-${columns}-${columns > 3 ? "full" : "sidebar"}`}
                    offcanvas={offcanvas}
                />


    );

    const sidebarComponent = (
        <WidgetFilters
            filters={filtersData}
            dispatch={dispatch}
            stateFilters={state}
            values={state.filters}
            maxPrice={state.productsList?.max_price || 1000}
            catID={catID}
            // maxPrice={maxPrice}
            // minPrice={minPrice}
            initialMaxPrice={initialMaxPrice}
            initialMinPrice={initialMinPrice}
        />
    );


    if (columns > 3) {
        content = (
            <div className="container_fm">
                {sidebarComponent}
                <div className="block">{productsView}</div>
            </div>
        );
    } else {

        const sidebar = state.productsList?.data.length > 0 || Object.keys(state.filters).length ?
            <div className="shop-layout__sidebar">{sidebarComponent}</div>
            : "";

        const sortOptions = [
            {id: "default", label: <FormattedMessage id="default" defaultMessage="Default"/>},
            {id: "price=asc", label: <FormattedMessage id="price_low_high" defaultMessage="Price (Low-High)"/>},
            {id: "price=desc", label: <FormattedMessage id="price_high_low" defaultMessage="Price (High-Low)"/>},
            {id: "name=asc", label: <FormattedMessage id="name_a_to_z" defaultMessage="Name (from A to Z)"/>},
            {id: "name=desc", label: <FormattedMessage id="name_z_to_a" defaultMessage="Name (from Z to A)"/>},
            {id: "created_at=asc", label: <FormattedMessage id="old_to_new" defaultMessage="Old to new"/>},
            {id: "created_at=desc", label: <FormattedMessage id="new_to_old" defaultMessage="New to old"/>}
        ];

        const selected = () => {
            const selectedOption = sortOptions.find((option) => option.id === `${state.filters.sort}=${state.filters.order}`)
            return selectedOption ? selectedOption : {label: <FormattedMessage id="sort_by" defaultMessage="Sort by"/>}
        }

        content = (
            <div className="container_fm asd">
                <div className={`shop-layout shop-layout--sidebar--${sidebarPosition}`}>
                    {/* filters Content */}
                    {sidebar}
                    {/* filters Content \\\ */}
                    <div className="shop-layout__content">
                        {/* {brands[0] && <BrandsByCategory brands={brands} dispatch={dispatch} selectedBrandId={state.options.brand || null} />} */}

                        <div className="sort_by">
                            <div className="sort_by__container" ref={sortByRef}>
                                <div className="sort_by__block" onClick={() => setIsOpen(!isOpen)}>
                                    <span>
                                        {state.filters.sort === 'default' ?
                                            <FormattedMessage id="default" defaultMessage="Default"/> :
                                            selected().label
                                        }
                                    </span>
                                    <div className="sort_by__arrowDown">
                                        <VectorSvg style={{
                                            transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                                            transition: 'transform 0.3s ease'
                                        }}/>
                                    </div>
                                </div>
                                {isOpen && (
                                    <div className="sort_by__dropdown_content" >
                                        <div className="dropdown__container">
                                            {sortOptions.map(option => (
                                                <div
                                                    key={option.id}
                                                    id={option.id}
                                                    onClick={handleSelect}
                                                    className={
                                                        state.filters.order === option.id.split("=")[1] &&
                                                        state.filters.sort === option.id.split("=")[0]
                                                            ? "selected"
                                                            : ""
                                                    }
                                                >
                                                    {option.label}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="block">
                            {state.productsList?.data.length > 0 || Object.keys(state.filters).length ?
                                productsView
                                :
                                <div className="products-view__empty">
                                    <div className="products-view__empty-title">
                                        <FormattedMessage id="no_products_yet"
                                                          defaultMessage="There are no products yet!"/>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    let correctCategoryName = window.location.href.includes("/shop/catalog?savings=true") ? (
        <FormattedMessage id="menu.savings" defaultMessage="Savings"/>
    ) : (
        selectedCategory?.name
    );

    const breadcrumb = [
        {title: <FormattedMessage id="home" defaultMessage="home"/>, url: url.home()},
        {title: correctCategoryName, url: `${location.pathname}${location.search}`},
    ];

    return (
        <React.Fragment>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={categorySlug}/>
                <meta name="name" content={categorySlug}/>
                <meta property="og:url" content={window.location}/>
                <meta property="og:title" content={categorySlug}/>
            </Helmet>
            {openDialog && <ModalDialog/>}
            <Breadcrumbs breadcrumb={breadcrumb}/>
            <div className="cat_blocks_fms">
                <PageHeader header={correctCategoryName}/>
                {content}
            </div>
        </React.Fragment>
    );
}

ShopPageCategory.propTypes = {
    /**
     * Category slug.
     */
    categorySlug: PropTypes.string,
    /**
     * number of product columns (default: 3)
     */
    columns: PropTypes.number,
    /**
     * mode of viewing the list of products (default: 'grid')
     * one of ['grid', 'grid-with-features', 'list']
     */
    viewMode: PropTypes.oneOf(["grid", "grid-with-features", "list"]),
    /**
     * sidebar position (default: 'start')
     * one of ['start', 'end']
     * for LTR scripts "start" is "left" and "end" is "right"
     */
    sidebarPosition: PropTypes.oneOf(["start", "end"]),
};

ShopPageCategory.defaultProps = {
    columns: 3,
    viewMode: "grid",
    sidebarPosition: "start",
};

const mapStateToProps = (state) => ({
    sidebarState: state.sidebar,
    page: state.category,
    initialMaxPrice: state.general.initialMaxPrice,
    initialMinPrice: state.general.initialMinPrice,
});

// const mapDispatchToProps = () => ({
//     sidebarClose,
// });

const mapDispatchToProps = (dispatch) => ({
    sidebarClose: (payload) => dispatch(sidebarClose(payload)),
    setInitialMinPrice: (payload) => dispatch(setInitialMinPrice(payload)),
    setInitialMaxPrice: (payload) => dispatch(setInitialMaxPrice(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageCategory);
