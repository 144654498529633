// react
import React, {useEffect, useState} from 'react';
import {Link, matchPath, useHistory, useLocation} from 'react-router-dom'

import {connect, useDispatch, useSelector} from 'react-redux';
import Search from './Search';
import {Modal} from 'reactstrap';
import {CartSVG, Cross20Svg, LoginSVG, LogoSvg} from '../../svg';
// import { localeChange } from '../../store';
import LoginBlock from '../blocks/LoginBlock'
import {FormattedMessage} from 'react-intl';
import shopApi from '../../api/shop';
import MenuDropdown from './MenuDropdown';
import {useWindowWidth} from '../../hooks/useWindowWidth';
// import {cartRemoveAllItems} from "../../store/cart";
import classNames from "classnames";
import AccountLayoutLinks from "../account/AccountLayoutLinks";
import {setPages as setPagesArray} from "../../store/pages";


function NavPanel() {
    let location = useLocation()
    // const customer = useSelector(state => state.customer.token);
    const customer = useSelector(state => state.customer);
    const cart = useSelector(state => state.cart);
    const wishlist = useSelector(state => state.wishlist);
    const locale = useSelector((state) => state.locale);
    const dispatch = useDispatch()
    const [routes, setRoutes] = useState([])
    const [pages, setPages] = useState([])
    const [commonData, setCommonData] = useState([])
    const width = useWindowWidth()
    const history = useHistory();
    const dataCustomer = JSON.parse(localStorage.getItem("userData"));

    // useEffect(() => {
    //     if (history.location.pathname === "/thanks") {
    //         dispatch(cartRemoveAllItems());
    //     }
    // }, [])

    const getChildrensUrl = (categories, step) => {


        if (!categories) {
            return []
        }
        return categories?.map(item => {
            let separatedData = {
                name: item.name, slug: item.slug, mainCats: step === 1, id: item.id, showSubs: false
            }
            if (item.children && item.children.length > 0) {
                return {
                    ...separatedData,
                    children: JSON.parse(JSON.stringify(getChildrensUrl(item?.children, step + 1)))
                }

            }

            return separatedData
        })
    }

    useEffect(() => {
        shopApi.getMenus({locale})
            .then((res) => {
                let foundedNeccessaryPage = res.data.find(item => item.id === 11)
                let withoutAboutUsPage = res?.data?.filter(item => item.id !== 11)
                dispatch(setPagesArray(res.data))
                setPages([{
                    ...foundedNeccessaryPage,
                    children: withoutAboutUsPage && withoutAboutUsPage.length ? [...withoutAboutUsPage].map(el => {
                        return {...el, page: true}
                    }) : []
                }]);
            });
        shopApi.getCategories({locale}).then((categories) => {
            let categoryRoutes = getChildrensUrl(categories.categories, 1)

            setRoutes([...categoryRoutes])
        });

    }, [locale]);

    useEffect(() => {

        setCommonData([...routes, ...pages])
    }, [routes, pages])

    const [login, SetLogin] = useState(false)
    const [forgotPass, SetForgotPass] = useState(false)
    const [reg, SetReg] = useState(false)
    const [sign, SetSign] = useState(false)
    const [modal, SetModal] = useState(false)

    const clicked = (type, check) => {
        SetModal(true)
        if (type == 'login')
            SetLogin(check)

        if (type == 'reg')
            SetReg(check)

        if (type == 'sign')
            SetSign(check)

        if (type == 'forgot')
            SetForgotPass(check)
    }

    const close = (type, check) => {
        clicked(type, check)
    }

    const closeModal = () => {
        SetModal(false)
        SetSign(false)
        SetReg(false)
        SetLogin(false)
        SetForgotPass(false)
    }

    const mobileHeader = <>

        <div className="nav-panel__container">
            <div className="nav-panel__row">
                <div className="nav-panel__indicators">
                    <Link to="/">
                        <div className="nav_icon_fm new_nav_icon">
                            <LogoSvg/>
                        </div>
                    </Link>


                    <div className="nav_icon_fm ">
                        <MenuDropdown routes={commonData}/>
                    </div>
                </div>
            </div>
        </div>
    </>

    const accountPopup = <div className="account_popup">
        <AccountLayoutLinks/>
    </div>

    return (
        <>
            <Modal className="nav_modal_fm" isOpen={modal} toggle={closeModal} centered size="xl">
                <div className="quickview">
                    <button className="quickview__close" type="button" onClick={() => {
                        closeModal()
                    }}>
                        <Cross20Svg/>
                    </button>
                    <LoginBlock login={login} reg={reg} clicked={clicked} sign={sign} forgot={forgotPass} close={close}
                                closeModal={closeModal}/>
                </div>
            </Modal>
            <div className={"nav-panel "}>
                <div className="nav-panel__container container_fm ">
                    <div className="nav-panel__row">

                        <div className='logo-menu-wrapper'>
                            <div className='site_logo'>
                                <Link to="/">
                                    <div>
                                        <LogoSvg/>
                                    </div>
                                </Link>
                            </div>
                            <MenuDropdown routes={commonData}/>
                        </div>
                        <div className="nav-panel__indicators">

                            <div className="site-header__search">
                                <Search context="header"/>
                            </div>
                            <Link to={`/${locale}/shop/cart`}>
                                <div className="nav_icon_fm">
                                    <CartSVG/>
                                    <div className="count_cart_fm">{cart ? cart.items?.length : 0}</div>
                                </div>
                            </Link>
                            {customer.token ?
                                <Link to={`/${locale}/shop/wishlist`}>
                                    <div className="nav_icon_fm">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="21.509"
                                             viewBox="0 0 24 21.509">
                                            <path id="heart"
                                                  d="M12,21.509a1.4,1.4,0,0,1-.928-.351c-.97-.854-1.9-1.656-2.729-2.364l0,0A50.907,50.907,0,0,1,2.38,13.156,9.034,9.034,0,0,1,0,7.266,7.588,7.588,0,0,1,1.9,2.1,6.432,6.432,0,0,1,6.7,0a6.009,6.009,0,0,1,3.771,1.311A7.747,7.747,0,0,1,12,2.915a7.748,7.748,0,0,1,1.525-1.6A6.009,6.009,0,0,1,17.3,0a6.432,6.432,0,0,1,4.8,2.1A7.588,7.588,0,0,1,24,7.266a9.033,9.033,0,0,1-2.38,5.89,50.9,50.9,0,0,1-5.958,5.634c-.826.709-1.762,1.513-2.734,2.369a1.4,1.4,0,0,1-.928.351ZM6.7,1.416A5.046,5.046,0,0,0,2.937,3.065a6.173,6.173,0,0,0-1.532,4.2,7.6,7.6,0,0,0,2.057,4.986,50.1,50.1,0,0,0,5.788,5.46l0,0c.828.711,1.766,1.516,2.743,2.377.983-.862,1.922-1.669,2.752-2.381a50.108,50.108,0,0,0,5.788-5.459,7.6,7.6,0,0,0,2.057-4.986,6.173,6.173,0,0,0-1.532-4.2A5.046,5.046,0,0,0,17.3,1.416a4.641,4.641,0,0,0-2.911,1.015,6.859,6.859,0,0,0-1.622,1.891.884.884,0,0,1-1.525,0A6.853,6.853,0,0,0,9.615,2.431,4.641,4.641,0,0,0,6.7,1.416Zm0,0"
                                                  fill={"#000"}
                                            />
                                        </svg>
                                        <div className="count_cart_fm">{wishlist ? wishlist.length : 0}</div>
                                    </div>
                                </Link> : ''}
                            {!customer.token ?
                                <div className="nav_icon_fm" onClick={() => clicked('login', true)}>
                                    <LoginSVG/>
                                    <span className="login_nav_fm">
                                        <FormattedMessage id="log.in" defaultMessage="LogIn"/>
                                    </span>
                                    {customer.authenticated && accountPopup}
                                </div>
                                :
                                <Link to={`/${locale}/account/profile`}>
                                    <div className="nav_icon_fm signed_user">
                                        <FormattedMessage id="hello"
                                                          defaultMessage="Hello"/> {customer.role !== "subUser" ? dataCustomer?.name : dataCustomer?.first_name}
                                        {accountPopup}
                                    </div>
                                </Link>}
                            {!customer.token ?
                                <div className="nav_icon_fm">
                                    <button className="crat_butt_fm" onClick={() => clicked('reg', true)}>
                                        <FormattedMessage id="create.account" defaultMessage="Create Account"/>
                                    </button>
                                </div> : ''}
                        </div>
                    </div>
                </div>

                {/* mobile */}
                {width <= 768 ? mobileHeader : ""}
            </div>
        </>
    );
}


export default connect(
)(NavPanel);
