// react
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Breadcrumbs from "../shared/Breadcrumbs";
import shopApi from "../../api/shop";
import {FormattedMessage} from "react-intl";
import {url} from "../../services/utils";
import {url as urlForShare} from "../../helper";
import {useLocation} from "react-router-dom";
import "./product_inner.scss"
import ProductGalary from "./ProductGalary";
import AsyncAction from "../shared/AsyncAction";
import classNames from "classnames";
import {toast} from "react-toastify";
import InputNumber from "../shared/InputNumber";
import ConfigAttributes from "../shared/ConfigAttributes";
import {BoxSvg, Check12x9Svg, FacebookIconSVG, SeeMoreArrow} from "../../svg";
import {AddImages} from "../../store/images";
import {wishlistAddItem} from "../../store/wishlist";
import {cartAddItemNew} from "../../store/cart";
import {FacebookShareButton} from "react-share";
import RelatedItems from "../blocks/RelatedItems";
import {Helmet} from "react-helmet-async";
import {colorType} from "../../services/color";

const ProductInner = (props) => {
    const {url_key} = props
    const {locale, wishlist, customer, currency } = useSelector(state => state)
    const [product, setProduct] = useState({})
    const [relatedProducts, setRelatedProducts] = useState([])
    const [imagesData, setImagesData] = useState()
    const [simpleProduct, setSimpleProduct] = useState(null)
    const [state, setState] = useState({
        size: null,
        color: null,
        memory: null,
        ram: null,
        ml: null,
        sizeType: {},
    })
    const [goToFirstIndex, setGoToFirstIndex] = useState()
    const [quantity, setQuantity] = useState(0)
    const [boxQuantity, setBoxQuantity] = useState(0)
    const [details, setDetails] = useState([])
    const [isDetailsOpen, setIsDetailsOpen] = useState(false)
    const [isAllChecked, setIsAllChecked] = useState(true)
    const customerToken = useSelector((state) => state.customer.token)
    const api_token = localStorage.getItem("api_token")
    const wishListStorage = JSON.parse(localStorage.getItem("state"));
    const location = useLocation()
    const dispatch = useDispatch()
    let minQty = Boolean(simpleProduct?.min_qty) ? Number(simpleProduct?.min_qty) : 1;
    let checkAddProd = false;
    let cartProduct = null;


    useEffect(() => {
        shopApi.getProductByUrlKey(url_key, locale, customerToken, api_token)
            .then(res => res.data)
            .then((product) => {
                const {id, min_qty, variants, attribute, type, index, images, related_products, cats} = product
                const boxQty = product.type === "simple" ? product.box_qty : null;
                setProduct(product)
                setQuantity(Boolean(min_qty) ? Number(min_qty) : 1)
                setBoxQuantity(Number(product.box_qty))

                if (type === "configurable") {
                    setProduct(product)
                    setSimpleProduct(product)
                    setImagesData(variants[0] ? variants[0].images : [])

                    shopApi
                        .getMultipleRequests(id, locale)
                        .then((res) => {
                            if (res) {

                                Object.assign(product, {
                                    attribute: res[0].data,
                                    index: res[0].data.index,
                                    regular_price: res[0].data.regular_price,
                                    variant_images: res[0].data.variant_images,
                                    variant_prices: res[0].data.variant_prices,
                                    variants,
                                });

                                let filterVariant = variants.filter((e) => {
                                    return e.id === variants[0].id;
                                });

                                if (filterVariant.length > 0) {
                                    dispatch(AddImages(filterVariant[0].images));
                                }
                                selectDef(product, filterVariant[0]);

                                setImagesData(variants[0] ? variants[0].images : [])
                                setSimpleProduct(variants[0])
                                setQuantity(Boolean(variants[0].min_qty) ? Number(variants[0].min_qty) : 1)

                                let prod = simpleProduct;
                                let attrib = attribute.attributes;

                                let addItem = index[prod?.id];

                                // let colItem = attrib[0].options.find((item) => item.id == addItem["76"]);
                                // let sizeItem = attrib[1].options.find((item) => item.id == addItem["77"]);

                                // setAttributes([
                                //     {
                                //         attribute_name: "color",
                                //         option_label: colItem?.label,
                                //     },
                                //     {
                                //         attribute_name: "size",
                                //         option_label: sizeItem?.label,
                                //     }
                                // ])
                            }
                        })
                        .catch((error) => console.log(error));
                } else {
                    checkAddProd = true;
                    setProduct(product)
                    setSimpleProduct(product)
                    setImagesData(images)

                    dispatch(AddImages(images));
                }

                if(!!related_products.data.length){
                    setRelatedProducts(related_products.data)
                }else if (cats) {
                    let userToken = customer.token ? `&token=${customer.token}` : ""
                    fetch(`${urlForShare}/api/products?limit=8&category_id=${cats[cats.length - 1]}${userToken}&locale=${locale}`)
                        .then(response => response.json())
                        .then(res => {
                            let filtered = res?.data?.filter(item => item.id !== id)
                            setRelatedProducts([...filtered])
                        })
                }

            });
    }, [locale, location.pathname]);

    useEffect(() => {
        if (simpleProduct) {
            shopApi.getProductAttributes(simpleProduct?.id, locale)
                .then(res => res.data)
                .then((attributes) => {
                    setDetails(attributes.filter(el => el.id !== 25 && el.id !== 34))
                })
        }
    }, [simpleProduct, locale])

    useEffect(() => {
        if (simpleProduct) {
            setBoxQuantity(Number(simpleProduct.box_qty))
            if (wishListStorage.wishlist.length) {
                if (wishListStorage.wishlist.filter((e) => e.id == simpleProduct.id).length)
                    Object.assign(simpleProduct, {wishlist: true});
                else {
                    Object.assign(simpleProduct, {wishlist: false});
                }
            } else {
                Object.assign(simpleProduct, {wishlist: false});
            }
        }
    }, [simpleProduct, wishListStorage.wishlist.length])

    const sortObj = (obj) => {
        return Object.keys(obj)
            .sort()
            .reduce(function (result, key) {
                result[key] = Number(obj[key]);
                return result;
            }, {});
    };

    const handleChangeQuantity = (quantity) => {
        setQuantity(quantity > simpleProduct?.qty ? simpleProduct?.qty : quantity)
    };

    const handleChangeBoxQuantity = (quantity) => {
        setBoxQuantity(quantity > simpleProduct?.qty ? simpleProduct?.qty : quantity)
    };

    const handleChangeStyle = (event, code, id) => {
        // let itemList = [...document.getElementsByClassName(code + "_fm")];
        let itemList = document.getElementsByClassName(code + "_fm");
        let itemRadio = document.getElementById(code + id);

        for (let e of itemList) {
            e.style.borderColor = "#929292";
        }

        let item = document.getElementById(event.target.value + code);
        item.style.borderColor = "#FFD34E";

        event.target.checked = true;
        item.style.opacity = "1"
    };

    const goToFirstIndexFunc = () => {
        setGoToFirstIndex(1)

        setTimeout(() => {
            setGoToFirstIndex(null)
        }, 5);
    };

    const selectDef = (prod, simple) => {

        prod.attribute.attributes.forEach((element) => {

            setState({
                ...state,
                [element.code]: {
                    id: prod.index[simple.id][element.id],
                    code: element.code
                }
            });
        });
    }

    const changeImage = (product) => {
        let data;
        for (let i in product.variant_images) {
            if (i === product[0]) {
                data = product.variant_images[i];
                break;
            }
        }
        dispatch(AddImages(data))
    };

    const handleChange = (attrArray, type) => {
        let prod = product;
        let valueId = {};
        let attrCheck = {};
        let attr = {};
        let check = true;
        let prodId = null;

        product.super_attributes.forEach((e) => {
            attr = Object.assign(attr, {[e.code]: [...document.getElementsByName(e.code)]});
            attrCheck = Object.assign(attrCheck, {[e.code]: [false, e.id]});
        });

        for (let attrItem in attr) {
            for (let i of attr[attrItem]) {
                if (i.checked) {
                    valueId = Object.assign(valueId, {[attrCheck[attrItem][1]]: Number(i.value)});
                    attrCheck[attrItem][0] = true;
                }
            }
        }

        for (let attrItem in attrCheck) {
            if (attrCheck[attrItem][0] === false) {
                check = false;
                break;
            }
        }

        valueId = JSON.stringify(sortObj(valueId)).slice(1, -1)

        for (let item in prod.index) {
            let elem = JSON.stringify(sortObj(prod.index[item]));

            if (elem.includes(valueId)) {
                prodId = item;
            }
        }

        let checkedArray = []
        product.attribute.attributes.forEach((element, i) => {

            if (element.code !== type && element.options.length !== 1) {
                element.options.forEach((option, index) => {
                    let item = document.getElementById(option.id + element.code);
                    let fill = []
                    for (let elem = 0; elem < attrArray.length; elem++) {
                        fill.push(...option.products.filter((es) => es === attrArray[elem]))
                    }
                    if (fill.length === 0) {
                        item.style.opacity = "0.4";
                        let radio = document.getElementById(element.code + option.id);
                        radio.checked = false
                    } else {
                        item.style.opacity = "1";
                        // break;
                    }
                });
            }

            let elementsArray = []
            for (let option of element.options) {
                elementsArray.push(document.getElementById(element.code + option.id).checked)
            }
            checkedArray.push(elementsArray.some(el => el === true))
        });

        setIsAllChecked(!checkedArray.some(el => el === false))

        if (prodId) {
            let item = prod.variants.filter((e) => e.id === Number(prodId));

            cartProduct = item[0];

            setSimpleProduct(cartProduct)
            setImagesData(cartProduct.images)
            setQuantity(Boolean(cartProduct.min_qty) ? Number(cartProduct.min_qty) : 1)

            selectDef(product, cartProduct);
        }
    };

    const filteredDetails = details.filter(detail => !!detail.value)

    const breadcrumb = [
        {title: <FormattedMessage id="home" defaultMessage="home"/>, url: url.home()},
        {
            title: product.name,
            url: `${location.pathname}${location.search}`
        },
    ];

    const emptyImage = [
        {
            large_image_url:
                " https://api.fooddepot.am/vendor/webkul/ui/assets/images/product/meduim-product-placeholder.png",
            medium_image_url:
                " https://api.fooddepot.am/vendor/webkul/ui/assets/images/product/meduim-product-placeholder.png",
            small_image_url:
                " https://api.fooddepot.am/vendor/webkul/ui/assets/images/product/meduim-product-placeholder.png",
            original_image_url:
                " https://api.fooddepot.am/vendor/webkul/ui/assets/images/product/meduim-product-placeholder.png",
        },
    ];

    const singleQtyPrice = (
        <div className="product_price">
            {simpleProduct?.old_price && (
                <span className="dis_price_fm">
                    {Math.round(simpleProduct?.old_price)}
                    <FormattedMessage id="global.curency" defaultMessage="AMD"/>
                </span>
            )}
            <span>
                {Math.round(simpleProduct?.price)} <FormattedMessage id="global.curency" defaultMessage="AMD"/>
            </span>
        </div>
    );

    const singleQtyBoxPrice = (
        <div className="product_box_price">
            <span className="not_box_price">
                <del>{Math.round(simpleProduct?.price) * Number(simpleProduct?.box_qty)} <FormattedMessage id="global.curency" defaultMessage="AMD"/></del>
            </span>
            <span className="box__price">
                {Math.round(simpleProduct?.box_price)} <FormattedMessage id="global.curency" defaultMessage="AMD"/>
            </span>
        </div>
    );

    const isSingleQty = Number(simpleProduct?.min_qty) <= 1 || !simpleProduct?.min_qty;

    if (product?.box_price || simpleProduct?.box_price) {
        return (
            <div>
                <Helmet>
                    <title>{simpleProduct?.meta_title || simpleProduct?.name}</title>
                    <meta
                        name="description"
                        content={
                            simpleProduct?.description
                                ? simpleProduct.description.replace(/(<([^>]+)>)/gi, "")
                                : ""
                        }
                    />
                    <meta
                        name="name"
                        content={simpleProduct?.name ? simpleProduct.name : ""}
                    />
                    <meta property="og:url" content={location.pathname}/>
                    <meta property="og:title" content={simpleProduct?.name}/>
                    <meta property="og:image" content={imagesData?.[0].original_image_url}/>
                </Helmet>

                <div className="product_inner_page">
                    <Breadcrumbs breadcrumb={breadcrumb}/>

                    <div className="container_fm">

                        {simpleProduct && <div className="product_content">
                            <ProductGalary
                                layout={"standard"}
                                images={imagesData?.length >= 1 ? imagesData : emptyImage}
                                toFirst={goToFirstIndex}
                            />

                            <div className="product_info">
                                <div className="product_name">
                                    <h1>{simpleProduct?.name}</h1>
                                    <AsyncAction
                                        action={() => {
                                            return dispatch(wishlistAddItem(
                                                simpleProduct,
                                                customer.token ? customer.token : false,
                                                simpleProduct.wishlist
                                            )).then((e) => {
                                                if (e == false)
                                                    toast.success(
                                                        <FormattedMessage
                                                            id="please.login"
                                                            defaultMessage="`Please login or register in order to add to favorites.`"
                                                        />
                                                    );
                                            });
                                        }}
                                        render={({run, loading}) => (
                                            <button
                                                type="button"
                                                onClick={run}
                                                className={`wishList_button_fm ${classNames({
                                                    "btn-loading": loading,
                                                })}`}
                                            >
                                                {!simpleProduct?.wishlist ? (
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="22.48"
                                                        height="20"
                                                        viewBox="0 0 22.48 20"
                                                    >
                                                        <path
                                                            id="heart"
                                                            d="M11.24,20a1.318,1.318,0,0,1-.869-.327c-.908-.794-1.784-1.54-2.556-2.2l0,0A47.539,47.539,0,0,1,2.23,12.233,8.367,8.367,0,0,1,0,6.756a7.026,7.026,0,0,1,1.783-4.8A6.045,6.045,0,0,1,6.28,0,5.654,5.654,0,0,1,9.812,1.219,7.226,7.226,0,0,1,11.24,2.71a7.227,7.227,0,0,1,1.428-1.491A5.654,5.654,0,0,1,16.2,0a6.045,6.045,0,0,1,4.5,1.957,7.026,7.026,0,0,1,1.783,4.8,8.366,8.366,0,0,1-2.229,5.477,47.534,47.534,0,0,1-5.581,5.238c-.774.659-1.651,1.407-2.561,2.2A1.319,1.319,0,0,1,11.24,20ZM6.28,1.317A4.743,4.743,0,0,0,2.751,2.85,5.716,5.716,0,0,0,1.317,6.756a7.04,7.04,0,0,0,1.927,4.637,46.782,46.782,0,0,0,5.421,5.077l0,0c.775.661,1.654,1.41,2.569,2.21.92-.8,1.8-1.552,2.578-2.214a46.793,46.793,0,0,0,5.421-5.076,7.041,7.041,0,0,0,1.927-4.637A5.716,5.716,0,0,0,19.729,2.85,4.742,4.742,0,0,0,16.2,1.317a4.367,4.367,0,0,0-2.727.944,6.39,6.39,0,0,0-1.52,1.758.831.831,0,0,1-1.428,0,6.384,6.384,0,0,0-1.52-1.758A4.367,4.367,0,0,0,6.28,1.317Zm0,0"
                                                            transform="translate(0 0)"
                                                            fill="#b0c472"
                                                        />
                                                    </svg>
                                                ) : (
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="22.48"
                                                        height="20"
                                                        viewBox="0 0 22.48 20"
                                                    >
                                                        <g
                                                            id="Сгруппировать_68760"
                                                            data-name="Сгруппировать 68760"
                                                            transform="translate(16954 18908)"
                                                        >
                                                            <path
                                                                id="heart"
                                                                d="M11.24,20a1.318,1.318,0,0,1-.869-.327c-.908-.794-1.784-1.54-2.556-2.2l0,0A47.539,47.539,0,0,1,2.23,12.233,8.367,8.367,0,0,1,0,6.756a7.026,7.026,0,0,1,1.783-4.8A6.045,6.045,0,0,1,6.28,0,5.654,5.654,0,0,1,9.812,1.219,7.226,7.226,0,0,1,11.24,2.71a7.227,7.227,0,0,1,1.428-1.491A5.654,5.654,0,0,1,16.2,0a6.045,6.045,0,0,1,4.5,1.957,7.026,7.026,0,0,1,1.783,4.8,8.366,8.366,0,0,1-2.229,5.477,47.534,47.534,0,0,1-5.581,5.238c-.774.659-1.651,1.407-2.561,2.2A1.319,1.319,0,0,1,11.24,20ZM.689,6.3c-1.364,0,8.505-.531,7.594.457A24,24,0,0,1,14.67,1.219c1.718-.9,4.074.161,4.074.738,0,.285.776,0,1.506.753A6.774,6.774,0,0,1,21.535,6.3a13.808,13.808,0,0,1-1.285,4.727c-.457.722-.927-.489-.522,0,1.288,1.554-10.524,4.329-2.3-.387,3.611,4.536-4.389,6.492-3.612,5.83a46.793,46.793,0,0,0,5.421-5.076,7.041,7.041,0,0,0,1.927-4.637A5.716,5.716,0,0,0,19.729,2.85,4.742,4.742,0,0,0,16.2,1.317a4.367,4.367,0,0,0-2.727.944c-.722.558,1.491-1.536,1.2-1.042-.152.254-6.091,9.806-6.387,9.806C19.434,4.721,8,16,7.275,15.443,6.465,14.817,1.689,6.3.689,6.3Z"
                                                                transform="translate(-16954 -18908)"
                                                                fill="#43b02a"
                                                            />
                                                        </g>
                                                    </svg>
                                                )}
                                            </button>
                                        )}
                                    />
                                </div>

                                {simpleProduct?.description ? (
                                    <div
                                        className="product_description"
                                        dangerouslySetInnerHTML={{__html: simpleProduct?.description}}
                                    />
                                ) : (
                                    ""
                                )}
                                <div className="product_details">
                                    {
                                        filteredDetails?.map((detail, index) => {
                                            const {code, label, value} = detail
                                            const renderedValue = code === "box_price" ?
                                                value + " ֏"
                                                :
                                                value

                                            if (!isDetailsOpen) {
                                                if (index < 3) {//25, 34
                                                    return <div key={index} className="product_detail">
                                                        <div className="detail_name">
                                                            {label}
                                                        </div>
                                                        <span> - </span>
                                                        <div className="detail_value">
                                                            {renderedValue}
                                                        </div>
                                                    </div>
                                                }
                                            } else {
                                                return <div key={index} className="product_detail">
                                                    <div className="detail_name">
                                                        {label}
                                                    </div>
                                                    <span> - </span>
                                                    <div className="detail_value">
                                                        {renderedValue}
                                                    </div>
                                                </div>
                                            }

                                        })
                                    }
                                    {filteredDetails.length > 3 &&
                                        <button className="see_more_btn"
                                                onClick={() => setIsDetailsOpen(!isDetailsOpen)}>
                                            {
                                                isDetailsOpen ?
                                                    <FormattedMessage id="see_less_innerpage"
                                                                      defaultMessage="See less"/>
                                                    :
                                                    <FormattedMessage id="see_more_innerpage"
                                                                      defaultMessage="See more"/>
                                            }
                                            <SeeMoreArrow className={isDetailsOpen ? "" : "close"}/>
                                        </button>
                                    }
                                </div>
                                <div className="product_attributes"
                                     style={!product?.attribute?.attributes.length ? {margin: "0"} : {}}>
                                    {/*<div className="product__meta">*/}
                                    {product && product?.attribute && product?.attribute?.attributes ? (
                                        product.attribute.attributes.map((attr, i) => {

                                            const label = (
                                                <div key={i} className="attribute_name">
                                                    <p>{attr.label}</p>
                                                </div>
                                            );

                                            if (attr.code !== "color") {
                                                return (
                                                    <ConfigAttributes
                                                        attr={attr}
                                                        goToFirstIndex={goToFirstIndexFunc}
                                                        handleChange={handleChange}
                                                        handleChangeStyle={handleChangeStyle}
                                                        label={label}
                                                        state={state}
                                                        key={i}
                                                    />

                                                );
                                            } else {
                                                return (
                                                    <div key={i} className="attr_color_fms">
                                                        {label}
                                                        {attr.options && attr.options.length && (
                                                            <div className="color-attr_fm">
                                                                {attr.options.map((e, i) => {
                                                                    return (
                                                                        <div
                                                                            key={i}
                                                                            className="filter-color__item"
                                                                            id={e.id + attr.code}
                                                                        >
                                                                <span
                                                                    className={classNames(
                                                                        "filter-color__check input-check-color", {
                                                                            "input-check-color--white": colorType(e.swatch_value) === "white",
                                                                            "input-check-color--light": colorType(e.swatch_value) === "light",
                                                                        }
                                                                    )}
                                                                    style={{color: `${e.swatch_value}`}}
                                                                >
                                                                    <label className="input-check-color__body">
                                                                        <input
                                                                            id={attr.code + e.id}
                                                                            className="input-check-color__input"
                                                                            name={attr.code}
                                                                            type="radio"
                                                                            value={e.id}
                                                                            checked={
                                                                                state[attr.code]?.id === e.id
                                                                            }
                                                                            onChange={(target) => {
                                                                                handleChange(
                                                                                    e.products,
                                                                                    attr.code
                                                                                );
                                                                                changeImage(e.products);
                                                                                goToFirstIndexFunc();
                                                                            }}
                                                                        />

                                                                        <span
                                                                            className="input-check-color__box"/>
                                                                        <Check12x9Svg
                                                                            className="input-check-color__icon"/>
                                                                        <span
                                                                            className="input-check-color__stick"/>
                                                                    </label>
                                                                </span>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        )}
                                                    </div>
                                                );
                                            }
                                        })
                                    ) : product.type === "configurable" ? (
                                        <>
                                            <div className="alternative-variants-block">
                                                <p>Color</p>
                                                <div className="animate-variants"/>
                                            </div>
                                            <div className="bottom-animate-variant">
                                                <p>Size</p>
                                                <div className="animate-variants"/>
                                            </div>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                    {/*</div>*/}
                                </div>
                                <div className="wish__stock_container">
                                    {simpleProduct?.qty > 0 ? (
                                        <div className="product_stock" style={{color: "#43B02A"}}>
                                            <FormattedMessage id="wishList.inStock" defaultMessage="In stock"/>
                                        </div>
                                    ) : (
                                        <div className="product_stock" style={{color: "red"}}>
                                            <FormattedMessage id="wishList.outOfStock" defaultMessage="Out of stock"/>
                                        </div>
                                    )}
                                </div>

                                <div className="block_1__box">
                                    <div className="block__name">
                                        <FormattedMessage id="sell_by_the_piece" defaultMessage="Piece"/>
                                    </div>
                                    <InputNumber
                                        id="product-quantity"
                                        aria-label="Quantity"
                                        className="product__quantity"
                                        size="lg"
                                        step={minQty}
                                        min={minQty}
                                        max={simpleProduct?.qty}
                                        value={quantity}
                                        disabled={simpleProduct?.qty <= 0}
                                        onChange={handleChangeQuantity}
                                    />

                                    {isSingleQty ? (
                                        <>{singleQtyPrice}</>
                                    ) : (
                                        <>
                                            <div className="product_price_fm_for_1">
                                            <span>
                                                <FormattedMessage id={"price_for_one"} defaultMessage="Price for 1"/>
                                            </span>
                                                {simpleProduct?.old_price && (
                                                    <del>
                                                        {Math.round(simpleProduct?.old_price)}{" "}
                                                        <span>
                                                        <FormattedMessage id="global.curency" defaultMessage="AMD"/>
                                                    </span>
                                                    </del>
                                                )}

                                                <span>{Math.round(simpleProduct?.price)}</span>
                                                <span><FormattedMessage id="global.curency"
                                                                        defaultMessage="AMD"/></span>
                                            </div>
                                            <div className="product_price_fm_for_pack">
                                                <span>
                                                    <FormattedMessage id={"price_for_pack"}
                                                                      defaultMessage="Price for Pack"/>
                                                    {Number(simpleProduct?.min_qty) > 1 && `(${Number(simpleProduct?.min_qty)})`}
                                                    {" - "}
                                                </span>
                                                <span>{Math.round(simpleProduct?.price) * Number(simpleProduct?.min_qty)} </span>
                                                <span><FormattedMessage id="global.curency"
                                                                        defaultMessage="AMD"/></span>
                                            </div>
                                        </>
                                    )}

                                    <div className="inner_actions_wrapper">
                                        <div className="add_card_fm">
                                            {simpleProduct ? (
                                                <>
                                                    <AsyncAction
                                                        action={() => {
                                                            if (simpleProduct.images[0]?.path) {
                                                                simpleProduct.images = [
                                                                    simpleProduct?.images[0]?.path,
                                                                ];
                                                            }


                                                            return dispatch(cartAddItemNew(
                                                                simpleProduct,
                                                                quantity,
                                                            ))
                                                        }}
                                                        render={({run, loading}) => (
                                                            <>
                                                                {product.type === "configurable" ? (
                                                                    <>
                                                                        <button
                                                                            type="button"
                                                                            onClick={run}
                                                                            disabled={simpleProduct?.qty <= 0 || !isAllChecked}
                                                                            className={classNames({
                                                                                "btn-loading": loading,
                                                                                "disabled": simpleProduct?.qty <= 0 || !isAllChecked
                                                                            })}
                                                                            // disabled={!product?.attribute}
                                                                        >
                                                                            <FormattedMessage
                                                                                id="add_to_cart"
                                                                                defaultMessage="Add to cart"
                                                                            />{" "}
                                                                            {/*{quantity > 1 && quantity}*/}
                                                                        </button>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <button
                                                                            type="button"
                                                                            onClick={run}
                                                                            disabled={simpleProduct?.qty <= 0}
                                                                            className={classNames({
                                                                                "btn-loading": loading,
                                                                                "disabled": simpleProduct?.qty <= 0
                                                                            })}
                                                                            // disabled={
                                                                            //     product?.data?.attribute || !product.data.qty
                                                                            //         ? true
                                                                            //         : false
                                                                            // }
                                                                        >
                                                                            <FormattedMessage
                                                                                id="add_to_cart"
                                                                                defaultMessage="Add to cart"
                                                                            />{" "}
                                                                            {Number(simpleProduct?.min_qty) > 1 ? quantity : ""}
                                                                        </button>
                                                                    </>
                                                                )}
                                                            </>
                                                        )}
                                                    />
                                                </>
                                            ) : (
                                                <AsyncAction
                                                    action={() => {
                                                        let prod = simpleProduct
                                                            ? simpleProduct
                                                            : product;

                                                        if (prod.images[0]?.path) {
                                                            prod.images = [prod?.images[0]?.path];
                                                        }

                                                        let attrib = product.data.attribute.attributes;
                                                        let indexes = product.data.index;
                                                        let addItem = indexes[prod.id];
                                                        let colItem = attrib[0].options.find(
                                                            (item) => item.id == addItem["76"]
                                                        );
                                                        let sizeItem = attrib[1].options.find(
                                                            (item) => item.id == addItem["77"]
                                                        );
                                                        let x = [
                                                            {
                                                                attribute_name: "color",
                                                                option_label: colItem?.label,
                                                            },
                                                            {
                                                                attribute_name: "size",
                                                                option_label: sizeItem?.label,
                                                            },
                                                        ];

                                                        return dispatch(cartAddItemNew(
                                                            prod,
                                                            quantity,
                                                        ))
                                                    }}
                                                    render={({run, loading}) => (
                                                        <>
                                                            <button
                                                                type="button"
                                                                onClick={run}
                                                                disabled={!Boolean(simpleProduct?.qty)}
                                                                className={classNames("btn btn-primary", {
                                                                    "btn-loading": loading,
                                                                    "disabled": !Boolean(simpleProduct?.qty)
                                                                })}
                                                            >
                                                                <FormattedMessage
                                                                    id="addtocart "
                                                                    defaultMessage="Ավելացնել քարտին"
                                                                />
                                                            </button>
                                                        </>
                                                    )}
                                                />
                                            )}
                                        </div>

                                        <div>
                                            <AsyncAction
                                                action={() => {
                                                    return dispatch(wishlistAddItem(
                                                        simpleProduct,
                                                        customer.token ? customer.token : false,
                                                        simpleProduct.wishlist
                                                    )).then((e) => {
                                                        if (e == false)
                                                            toast.success(
                                                                <FormattedMessage
                                                                    id="please.login"
                                                                    defaultMessage="`Please login or register in order to add to favorites.`"
                                                                />
                                                            );
                                                    });
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="blocks__line"></div>
                                <div className="block_2__box">
                                    <div className="block__name">
                                        <FormattedMessage id="sell_by_the_box" defaultMessage="Box"/>
                                    </div>
                                    {product.type === "configurable" ? (
                                        <InputNumber
                                            id="product-quantity"
                                            aria-label="Quantity"
                                            className="product__quantity"
                                            size="lg"
                                            step={Number(simpleProduct.box_qty)}
                                            min={Number(simpleProduct.box_qty)}
                                            max={simpleProduct?.qty}
                                            value={boxQuantity}
                                            disabled={simpleProduct?.qty <= 0}
                                            onChange={handleChangeBoxQuantity}
                                        />) : (
                                        <InputNumber
                                            id="product-quantity"
                                            aria-label="Quantity"
                                            className="product__quantity"
                                            size="lg"
                                            step={Number(product.box_qty)}
                                            min={Number(product.box_qty)}
                                            max={simpleProduct?.qty}
                                            value={boxQuantity}
                                            disabled={simpleProduct?.qty <= 0}
                                            onChange={handleChangeBoxQuantity}
                                        />)
                                    }

                                    {isSingleQty ? (
                                        <>{singleQtyBoxPrice}</>
                                    ) : (
                                        <>
                                            <div className="product_price_fm_for_1">
                                    <span>
                                        <FormattedMessage id={"price_for_one"} defaultMessage="Price for 1"/>
                                    </span>
                                                {simpleProduct?.old_price && (
                                                    <del>
                                                        {Math.round(simpleProduct?.old_price)}{" "}
                                                        <span>
                                            <FormattedMessage id="global.curency" defaultMessage="AMD"/>
                                        </span>
                                                    </del>
                                                )}

                                                <span>{Math.round(simpleProduct?.price)} </span>
                                                <span>
                                    <FormattedMessage id="global.curency" defaultMessage="AMD"/>
                                    </span>
                                            </div>
                                            <div className="product_price_fm_for_pack">
                                    <span>
                                        <FormattedMessage id={"price_for_pack"} defaultMessage="Price for Pack"/>
                                        {Number(simpleProduct?.min_qty) > 1 && `(${Number(simpleProduct?.min_qty)})`}
                                        {" - "}
                                    </span>
                                                <span>{Math.round(simpleProduct?.price) * Number(simpleProduct?.min_qty)} </span>
                                                <span>
                                        <FormattedMessage id="global.curency" defaultMessage="AMD"/>
                                    </span>
                                            </div>
                                        </>
                                    )}

                                    <div className="inner_actions_wrapper">
                                        <div className="add_card_fm">
                                            {simpleProduct ? (
                                                <>
                                                    <AsyncAction
                                                        action={() => {
                                                            if (simpleProduct.images[0]?.path) {
                                                                simpleProduct.images = [
                                                                    simpleProduct?.images[0]?.path,
                                                                ];
                                                            }


                                                            return dispatch(cartAddItemNew(
                                                                simpleProduct,
                                                                boxQuantity,
                                                            ))
                                                        }}
                                                        render={({run, loading}) => (
                                                            <>
                                                                {product.type === "configurable" ? (
                                                                    <>
                                                                        <button
                                                                            type="button"
                                                                            onClick={run}
                                                                            disabled={simpleProduct?.qty <= 0 || !isAllChecked}
                                                                            className={classNames({
                                                                                "btn-loading": loading,
                                                                                "disabled": simpleProduct?.qty <= 0 || !isAllChecked
                                                                            })}
                                                                            // disabled={!product?.attribute}
                                                                        >
                                                                            <BoxSvg/>
                                                                            <FormattedMessage
                                                                                id="add_to_cart"
                                                                                defaultMessage="Add to cart"
                                                                            />{" "}
                                                                            {/*{quantity > 1 && quantity}*/}
                                                                        </button>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <button
                                                                            type="button"
                                                                            onClick={run}
                                                                            disabled={simpleProduct?.qty <= 0}
                                                                            className={classNames({
                                                                                "btn-loading": loading,
                                                                                "disabled": simpleProduct?.qty <= 0
                                                                            })}
                                                                            // disabled={
                                                                            //     product?.data?.attribute || !product.data.qty
                                                                            //         ? true
                                                                            //         : false
                                                                            // }
                                                                        >
                                                                            <BoxSvg/>
                                                                            <FormattedMessage
                                                                                id="add_to_cart"
                                                                                defaultMessage="Add to cart"
                                                                            />{" "}
                                                                            {Number(simpleProduct?.min_qty) > 1 ? quantity : ""}
                                                                        </button>
                                                                    </>
                                                                )}
                                                            </>
                                                        )}
                                                    />
                                                </>
                                            ) : (
                                                <AsyncAction
                                                    action={() => {
                                                        let prod = simpleProduct
                                                            ? simpleProduct
                                                            : product;

                                                        if (prod.images[0]?.path) {
                                                            prod.images = [prod?.images[0]?.path];
                                                        }

                                                        let attrib = product.data.attribute.attributes;
                                                        let indexes = product.data.index;
                                                        let addItem = indexes[prod.id];
                                                        let colItem = attrib[0].options.find(
                                                            (item) => item.id == addItem["76"]
                                                        );
                                                        let sizeItem = attrib[1].options.find(
                                                            (item) => item.id == addItem["77"]
                                                        );
                                                        let x = [
                                                            {
                                                                attribute_name: "color",
                                                                option_label: colItem?.label,
                                                            },
                                                            {
                                                                attribute_name: "size",
                                                                option_label: sizeItem?.label,
                                                            },
                                                        ];

                                                        return dispatch(cartAddItemNew(
                                                            prod,
                                                            quantity,
                                                        ))
                                                    }}
                                                    render={({run, loading}) => (
                                                        <>
                                                            <button
                                                                type="button"
                                                                onClick={run}
                                                                disabled={!Boolean(simpleProduct?.qty)}
                                                                className={classNames("btn btn-primary", {
                                                                    "btn-loading": loading,
                                                                    "disabled": !Boolean(simpleProduct?.qty)
                                                                })}
                                                            >
                                                                <FormattedMessage
                                                                    id="addtocart "
                                                                    defaultMessage="Ավելացնել քարտին"
                                                                />
                                                            </button>
                                                        </>
                                                    )}
                                                />
                                            )}
                                            <span className="buy__more">
                                                <FormattedMessage
                                                    id="buy_more_save_more"
                                                    defaultMessage="Buy more, save more"
                                                />
                                            </span>
                                        </div>

                                        <div>
                                            <AsyncAction
                                                action={() => {
                                                    return dispatch(wishlistAddItem(
                                                        simpleProduct,
                                                        customer.token ? customer.token : false,
                                                        simpleProduct.wishlist
                                                    )).then((e) => {
                                                        if (e == false)
                                                            toast.success(
                                                                <FormattedMessage
                                                                    id="please.login"
                                                                    defaultMessage="`Please login or register in order to add to favorites.`"
                                                                />
                                                            );
                                                    });
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="product_share">
                                    <span> <FormattedMessage id="share" defaultMessage="Share"/> : </span>
                                    <div className="share_icons">
                                        {/*<FacebookShareButton url={`${url}/shop/products/${product.id}`}>*/}
                                        <FacebookShareButton url={`${urlForShare}${location.pathname}`}>
                                            <FacebookIconSVG/>
                                        </FacebookShareButton>
                                    </div>
                                </div>
                            </div>
                        </div>}

                        {/*{product?.cats?.length > 0 ? <RelatedItems product={product}/> : null}*/}
                        <RelatedItems relatedProducts={relatedProducts}/>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div>
            <Helmet>
                <title>{simpleProduct?.meta_title || simpleProduct?.name}</title>
                <meta
                    name="description"
                    content={
                        simpleProduct?.description
                            ? simpleProduct.description.replace(/(<([^>]+)>)/gi, "")
                            : ""
                    }
                />
                <meta
                    name="name"
                    content={simpleProduct?.name ? simpleProduct.name : ""}
                />
                <meta property="og:url" content={location.pathname}/>
                <meta property="og:title" content={simpleProduct?.name}/>
                <meta property="og:image" content={imagesData?.[0].original_image_url}/>
            </Helmet>

            <div className="product_inner_page">
                <Breadcrumbs breadcrumb={breadcrumb}/>

                <div className="container_fm">

                    {simpleProduct && <div className="product_content">
                        <ProductGalary
                            layout={"standard"}
                            images={imagesData?.length >= 1 ? imagesData : emptyImage}
                            toFirst={goToFirstIndex}
                        />

                        <div className="product_info">
                            <div className="product_name">
                                <h1>{simpleProduct?.name}</h1>
                            </div>

                            {simpleProduct?.description ? (
                                <div
                                    className="product_description"
                                    dangerouslySetInnerHTML={{__html: simpleProduct?.description}}
                                />
                            ) : (
                                ""
                            )}

                            <div className="product_details">
                                {
                                    filteredDetails?.map((detail, index) => {
                                        const {code, label, value} = detail
                                        const renderedValue = code === "box_price" ?
                                            value + " ֏"
                                            :
                                            value

                                        if (!isDetailsOpen) {
                                            if (index < 3) {//25, 34
                                                return <div key={index} className="product_detail">
                                                    <div className="detail_name">
                                                        {label}
                                                    </div>
                                                    <span> - </span>
                                                    <div className="detail_value">
                                                        {renderedValue}
                                                    </div>
                                                </div>
                                            }
                                        } else {
                                            return <div key={index} className="product_detail">
                                                <div className="detail_name">
                                                    {label}
                                                </div>
                                                <span> - </span>
                                                <div className="detail_value">
                                                    {renderedValue}
                                                </div>
                                            </div>
                                        }

                                    })
                                }
                                {filteredDetails.length > 3 &&
                                    <button className="see_more_btn" onClick={() => setIsDetailsOpen(!isDetailsOpen)}>
                                        {
                                            isDetailsOpen ?
                                                <FormattedMessage id="see_less_innerpage" defaultMessage="See less"/>
                                                :
                                                <FormattedMessage id="see_more_innerpage" defaultMessage="See more"/>
                                        }
                                        <SeeMoreArrow className={isDetailsOpen ? "" : "close"}/>
                                    </button>}
                            </div>

                            <div className="product_attributes"
                                 style={!product?.attribute?.attributes.length ? {margin: "0"} : {}}>
                                {/*<div className="product__meta">*/}
                                {product && product?.attribute && product?.attribute?.attributes ? (
                                    product.attribute.attributes.map((attr, i) => {

                                        const label = (
                                            <div key={i} className="attribute_name">
                                                <p>{attr.label}</p>
                                            </div>
                                        );

                                        if (attr.code !== "color") {
                                            return (
                                                <ConfigAttributes
                                                    attr={attr}
                                                    goToFirstIndex={goToFirstIndexFunc}
                                                    handleChange={handleChange}
                                                    handleChangeStyle={handleChangeStyle}
                                                    label={label}
                                                    state={state}
                                                    key={i}
                                                />

                                            );
                                        } else {
                                            return (
                                                <div key={i} className="attr_color_fms">
                                                    {label}
                                                    {attr.options && attr.options.length && (
                                                        <div className="color-attr_fm">
                                                            {attr.options.map((e, i) => {
                                                                return (
                                                                    <div
                                                                        key={i}
                                                                        className="filter-color__item"
                                                                        id={e.id + attr.code}
                                                                    >
                                                                <span
                                                                    className={classNames(
                                                                        "filter-color__check input-check-color", {
                                                                            "input-check-color--white": colorType(e.swatch_value) === "white",
                                                                            "input-check-color--light": colorType(e.swatch_value) === "light",
                                                                        }
                                                                    )}
                                                                    style={{color: `${e.swatch_value}`}}
                                                                >
                                                                    <label className="input-check-color__body">
                                                                        <input
                                                                            id={attr.code + e.id}
                                                                            className="input-check-color__input"
                                                                            name={attr.code}
                                                                            type="radio"
                                                                            value={e.id}
                                                                            checked={
                                                                                state[attr.code]?.id === e.id
                                                                            }
                                                                            onChange={(target) => {
                                                                                handleChange(
                                                                                    e.products,
                                                                                    attr.code
                                                                                );
                                                                                changeImage(e.products);
                                                                                goToFirstIndexFunc();
                                                                            }}
                                                                        />

                                                                        <span
                                                                            className="input-check-color__box"/>
                                                                        <Check12x9Svg
                                                                            className="input-check-color__icon"/>
                                                                        <span
                                                                            className="input-check-color__stick"/>
                                                                    </label>
                                                                </span>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    )}
                                                </div>
                                            );
                                        }
                                    })
                                ) : product.type === "configurable" ? (
                                    <>
                                        <div className="alternative-variants-block">
                                            <p>Color</p>
                                            <div className="animate-variants"/>
                                        </div>
                                        <div className="bottom-animate-variant">
                                            <p>Size</p>
                                            <div className="animate-variants"/>
                                        </div>
                                    </>
                                ) : (
                                    <></>
                                )}
                                {/*</div>*/}
                            </div>

                            {isSingleQty ? (
                                <>{singleQtyPrice}</>
                            ) : (
                                <>
                                    <div className="product_price_fm_for_1">
                                <span>
                                    <FormattedMessage id={"price_for_one"} defaultMessage="Price for 1"/>
                                </span>
                                        {simpleProduct?.old_price && (
                                            <del>
                                                {Math.round(simpleProduct?.old_price)}{" "}
                                                <span>
                                            <FormattedMessage id="global.curency" defaultMessage="AMD"/>
                                        </span>
                                            </del>
                                        )}

                                        <span>{Math.round(simpleProduct?.price)} </span>
                                        <span>
                                    <FormattedMessage id="global.curency" defaultMessage="AMD"/>
                                </span>
                                    </div>
                                    <div className="product_price_fm_for_pack">
                                <span>
                                    <FormattedMessage id={"price_for_pack"} defaultMessage="Price for Pack"/>
                                    {Number(simpleProduct?.min_qty) > 1 && `(${Number(simpleProduct?.min_qty)})`}
                                    {" - "}
                                </span>
                                        <span>{Math.round(simpleProduct?.price) * Number(simpleProduct?.min_qty)} </span>
                                        <span>
                                    <FormattedMessage id="global.curency" defaultMessage="AMD"/>
                                </span>
                                    </div>
                                </>
                            )}

                            {simpleProduct?.qty > 0 ? (
                                <div className="product_stock" style={{color: "#43B02A"}}>
                                    <FormattedMessage id="wishList.inStock" defaultMessage="In stock"/>
                                </div>
                            ) : (
                                <div className="product_stock" style={{color: "red"}}>
                                    <FormattedMessage id="wishList.outOfStock" defaultMessage="Out of stock"/>
                                </div>
                            )}

                            <div className="product_qty">
                                <FormattedMessage id="global.quantity" defaultMessage="Quantity"/>:
                            </div>

                            <div className="block_1">
                                <InputNumber
                                    id="product-quantity"
                                    aria-label="Quantity"
                                    className="product__quantity"
                                    size="lg"
                                    step={minQty}
                                    min={minQty}
                                    max={simpleProduct?.qty}
                                    value={quantity}
                                    disabled={simpleProduct?.qty <= 0}
                                    onChange={handleChangeQuantity}
                                />

                                <div className="inner_actions_wrapper">
                                    <div className="add_card_fm">
                                        {simpleProduct ? (
                                            <>
                                                <AsyncAction
                                                    action={() => {
                                                        if (simpleProduct.images[0]?.path) {
                                                            simpleProduct.images = [
                                                                simpleProduct?.images[0]?.path,
                                                            ];
                                                        }


                                                        return dispatch(cartAddItemNew(
                                                            simpleProduct,
                                                            quantity,
                                                        ))
                                                    }}
                                                    render={({run, loading}) => (
                                                        <>
                                                            {product.type === "configurable" ? (
                                                                <>
                                                                    <button
                                                                        type="button"
                                                                        onClick={run}
                                                                        disabled={simpleProduct?.qty <= 0 || !isAllChecked}
                                                                        className={classNames({
                                                                            "btn-loading": loading,
                                                                            "disabled": simpleProduct?.qty <= 0 || !isAllChecked
                                                                        })}
                                                                        // disabled={!product?.attribute}
                                                                    >
                                                                        <FormattedMessage
                                                                            id="add_to_cart"
                                                                            defaultMessage="Add to cart"
                                                                        />{" "}
                                                                        {quantity > 1 && quantity}
                                                                    </button>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <button
                                                                        type="button"
                                                                        onClick={run}
                                                                        disabled={simpleProduct?.qty <= 0}
                                                                        className={classNames({
                                                                            "btn-loading": loading,
                                                                            "disabled": simpleProduct?.qty <= 0
                                                                        })}
                                                                        // disabled={
                                                                        //     product?.data?.attribute || !product.data.qty
                                                                        //         ? true
                                                                        //         : false
                                                                        // }
                                                                    >
                                                                        <FormattedMessage
                                                                            id="add_to_cart"
                                                                            defaultMessage="Add to cart"
                                                                        />{" "}
                                                                        {Number(simpleProduct?.min_qty) > 1 ? quantity : ""}
                                                                    </button>
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                />
                                            </>
                                        ) : (
                                            <AsyncAction
                                                action={() => {
                                                    let prod = simpleProduct
                                                        ? simpleProduct
                                                        : product;

                                                    if (prod.images[0]?.path) {
                                                        prod.images = [prod?.images[0]?.path];
                                                    }

                                                    let attrib = product.data.attribute.attributes;
                                                    let indexes = product.data.index;
                                                    let addItem = indexes[prod.id];
                                                    let colItem = attrib[0].options.find(
                                                        (item) => item.id == addItem["76"]
                                                    );
                                                    let sizeItem = attrib[1].options.find(
                                                        (item) => item.id == addItem["77"]
                                                    );
                                                    let x = [
                                                        {
                                                            attribute_name: "color",
                                                            option_label: colItem?.label,
                                                        },
                                                        {
                                                            attribute_name: "size",
                                                            option_label: sizeItem?.label,
                                                        },
                                                    ];

                                                    return dispatch(cartAddItemNew(
                                                        prod,
                                                        quantity,
                                                    ))
                                                }}
                                                render={({run, loading}) => (
                                                    <>
                                                        <button
                                                            type="button"
                                                            onClick={run}
                                                            disabled={!Boolean(simpleProduct?.qty)}
                                                            className={classNames("btn btn-primary", {
                                                                "btn-loading": loading,
                                                                "disabled": !Boolean(simpleProduct?.qty)
                                                            })}
                                                        >
                                                            <FormattedMessage
                                                                id="addtocart "
                                                                defaultMessage="Ավելացնել քարտին"
                                                            />
                                                        </button>
                                                    </>
                                                )}
                                            />
                                        )}
                                    </div>

                                    <div>
                                        <AsyncAction
                                            action={() => {
                                                return dispatch(wishlistAddItem(
                                                    simpleProduct,
                                                    customer.token ? customer.token : false,
                                                    simpleProduct.wishlist
                                                )).then((e) => {
                                                    if (e == false)
                                                        toast.success(
                                                            <FormattedMessage
                                                                id="please.login"
                                                                defaultMessage="`Please login or register in order to add to favorites.`"
                                                            />
                                                        );
                                                });
                                            }}
                                            render={({run, loading}) => (
                                                <button
                                                    type="button"
                                                    onClick={run}
                                                    className={`wishList_button_fm ${classNames({
                                                        "btn-loading": loading,
                                                    })}`}
                                                >
                                                    {!simpleProduct?.wishlist ? (
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="22.48"
                                                            height="20"
                                                            viewBox="0 0 22.48 20"
                                                        >
                                                            <path
                                                                id="heart"
                                                                d="M11.24,20a1.318,1.318,0,0,1-.869-.327c-.908-.794-1.784-1.54-2.556-2.2l0,0A47.539,47.539,0,0,1,2.23,12.233,8.367,8.367,0,0,1,0,6.756a7.026,7.026,0,0,1,1.783-4.8A6.045,6.045,0,0,1,6.28,0,5.654,5.654,0,0,1,9.812,1.219,7.226,7.226,0,0,1,11.24,2.71a7.227,7.227,0,0,1,1.428-1.491A5.654,5.654,0,0,1,16.2,0a6.045,6.045,0,0,1,4.5,1.957,7.026,7.026,0,0,1,1.783,4.8,8.366,8.366,0,0,1-2.229,5.477,47.534,47.534,0,0,1-5.581,5.238c-.774.659-1.651,1.407-2.561,2.2A1.319,1.319,0,0,1,11.24,20ZM6.28,1.317A4.743,4.743,0,0,0,2.751,2.85,5.716,5.716,0,0,0,1.317,6.756a7.04,7.04,0,0,0,1.927,4.637,46.782,46.782,0,0,0,5.421,5.077l0,0c.775.661,1.654,1.41,2.569,2.21.92-.8,1.8-1.552,2.578-2.214a46.793,46.793,0,0,0,5.421-5.076,7.041,7.041,0,0,0,1.927-4.637A5.716,5.716,0,0,0,19.729,2.85,4.742,4.742,0,0,0,16.2,1.317a4.367,4.367,0,0,0-2.727.944,6.39,6.39,0,0,0-1.52,1.758.831.831,0,0,1-1.428,0,6.384,6.384,0,0,0-1.52-1.758A4.367,4.367,0,0,0,6.28,1.317Zm0,0"
                                                                transform="translate(0 0)"
                                                                fill="#b0c472"
                                                            />
                                                        </svg>
                                                    ) : (
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="22.48"
                                                            height="20"
                                                            viewBox="0 0 22.48 20"
                                                        >
                                                            <g
                                                                id="Сгруппировать_68760"
                                                                data-name="Сгруппировать 68760"
                                                                transform="translate(16954 18908)"
                                                            >
                                                                <path
                                                                    id="heart"
                                                                    d="M11.24,20a1.318,1.318,0,0,1-.869-.327c-.908-.794-1.784-1.54-2.556-2.2l0,0A47.539,47.539,0,0,1,2.23,12.233,8.367,8.367,0,0,1,0,6.756a7.026,7.026,0,0,1,1.783-4.8A6.045,6.045,0,0,1,6.28,0,5.654,5.654,0,0,1,9.812,1.219,7.226,7.226,0,0,1,11.24,2.71a7.227,7.227,0,0,1,1.428-1.491A5.654,5.654,0,0,1,16.2,0a6.045,6.045,0,0,1,4.5,1.957,7.026,7.026,0,0,1,1.783,4.8,8.366,8.366,0,0,1-2.229,5.477,47.534,47.534,0,0,1-5.581,5.238c-.774.659-1.651,1.407-2.561,2.2A1.319,1.319,0,0,1,11.24,20ZM.689,6.3c-1.364,0,8.505-.531,7.594.457A24,24,0,0,1,14.67,1.219c1.718-.9,4.074.161,4.074.738,0,.285.776,0,1.506.753A6.774,6.774,0,0,1,21.535,6.3a13.808,13.808,0,0,1-1.285,4.727c-.457.722-.927-.489-.522,0,1.288,1.554-10.524,4.329-2.3-.387,3.611,4.536-4.389,6.492-3.612,5.83a46.793,46.793,0,0,0,5.421-5.076,7.041,7.041,0,0,0,1.927-4.637A5.716,5.716,0,0,0,19.729,2.85,4.742,4.742,0,0,0,16.2,1.317a4.367,4.367,0,0,0-2.727.944c-.722.558,1.491-1.536,1.2-1.042-.152.254-6.091,9.806-6.387,9.806C19.434,4.721,8,16,7.275,15.443,6.465,14.817,1.689,6.3.689,6.3Z"
                                                                    transform="translate(-16954 -18908)"
                                                                    fill="#43b02a"
                                                                />
                                                            </g>
                                                        </svg>
                                                    )}
                                                </button>
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="product_share">
                                <span> <FormattedMessage id="share" defaultMessage="Share"/> : </span>
                                <div className="share_icons">
                                    {/*<FacebookShareButton url={`${url}/shop/products/${product.id}`}>*/}
                                    <FacebookShareButton url={`${urlForShare}${location.pathname}`}>
                                        <FacebookIconSVG/>
                                    </FacebookShareButton>
                                </div>
                            </div>
                        </div>
                    </div>}

                    {/*{product?.cats?.length > 0 ? <RelatedItems product={product}/> : null}*/}
                    <RelatedItems relatedProducts={relatedProducts}/>
                </div>
            </div>
        </div>
    )
}

export default ProductInner
